function setCookie(name, value, daysToExpire, path = '/') {
  let expires = "";
    if (daysToExpire) {
      const date = new Date();
      date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
  document.cookie = name + "=" + encodeURIComponent(value || "") + expires + "; path=" + path;
  }
  
  function getCookie(name) {
    const cDecoded = decodeURIComponent(document.cookie);
    const cArray = cDecoded.split(';');
    const matchingCookie = cArray.find(cookie => {
      const trimmedCookie = cookie.trim(); // Remove leading/trailing whitespace
      return trimmedCookie.startsWith(name + '=');
    });
    return matchingCookie ? matchingCookie.substring(name.length + 2) : null;
    }

    export {setCookie, getCookie}

