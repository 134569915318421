import "./App.css";
import { useLocation, Navigate, Routes, Route, Outlet, useOutletContext } from "react-router-dom";
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { motion, AnimatePresence } from "framer-motion";

import useAuth from "./hooks/useAuth";

import Topbar from "./components/Topbar/topbar.jsx";
import Header from "./components/Header/header";
import ScrollIcon from "./components/Scroll/Scroll.jsx";
import Footer from "./components/Footer/footer";
import Bot from "./components/Widget/chatbot.jsx";

import HomePage from "./pages/Home/home";

import Privacy from "./pages/Privacy/privacy";
import Questions from "./pages/Faqs/faqs";
import Quotes from "./pages/Quotes/quotes";
import CookieBanner from "./components/Cookies/cookies";
import Complaints from "./pages/Complaints/complaints.jsx";
import Terms from "./pages/Terms/Terms.jsx";

import BlogSingle from "./pages/Blog/blogSingle.jsx";

import Login from "./pages/Login/login.jsx";
import Unauthorized from "./pages/Login/Unauthorized.jsx";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./hooks/persistlogin.jsx";
import Dashboard from "./pages/Login/dashboard.jsx";
import Layout from "./pages/Login/layout.jsx";
import Users from "./pages/Login/Users/users.jsx";
import Register from "./pages/Login/Users/register.jsx";
import CareersAdd from "./pages/Login/Careers/careersportal.jsx";
import AddJob from "./pages/Login/Careers/addJob.jsx";
import EditJobIntro from "./pages/Login/Careers/editJob.jsx";
import AddBlog from "./pages/Login/Blogs/addBlog.jsx";
import BlogPortal from "./pages/Login/Blogs/portalBlog.jsx";
import EditBlogEntry from "./pages/Login/Blogs/editBlog.jsx";
import QuotesPortal from "./pages/Login/Quotes/quotes.jsx";
import Gas from "./pages/Gas/gas.jsx";
import Projects from "./pages/Projects/projects.jsx";

//AQUI ANIMATION - ALFONSO
import Animation from "./components/suspenseAnimation/suspenseAnimation.jsx";

import "./pages/css/plugins.css";
import "./pages/css/style.css";
import Applicants from "./pages/Login/Careers/applicants.jsx";
import ApplicantSingle from "./pages/Login/Careers/applicantSingle.jsx";
import QuoteSingle from "./pages/Login/Quotes/quoteSingle.jsx";
import ComplaintsPortal from "./pages/Login/Complaints/complaintsPortal.jsx";
import ComplaintSingle from "./pages/Login/Complaints/complaintSingle.jsx";

// import { simulatedCall } from './components/simulatedCall';

// import 'bootstrap/dist/css/bootstrap.min.css';

const Services = lazy(() => import("./pages/Services/services"));//done
const Clients = lazy(() => import("./pages/Clients/clients"));//done
const About = lazy(() => import("./pages/About/about"));//done
const Blog = lazy(() => import("./pages/Blog/blog"));//done
const Contact = lazy(() => import("./pages/Contact/contact"));//done
const Pricing = lazy(() => import("./pages/Pricing/pricing.jsx"));//done
const SiteMap = lazy(() => import("./pages/SiteMap/siteMap"));//done
const Careers = lazy(() => import("./pages/Careers/careers.jsx"));//done
const CareersIndividual = lazy(() => import("./pages/Careers/careersIndividual.jsx"));
const Confirmation = lazy(() => import("./pages/Confirmation/confirmation"));
const NotFound = lazy(() => import("./pages/NotFound/notfound"));


const ROLES = {
  Manager: 1999,
  Admin: 2000
}

const mainVariant = {
  initial: {
    x: 0,
    opacity: 0
  },
  final: {
    x: 0,
    opacity: 1,
    transition: {
      // type: "spring",
      // bounce: 0.4,
      duration: .3
    }
  }
};

function LocationProvider({ children }) {
  return <AnimatePresence>{children}</AnimatePresence>;
}

function NormalLayout() {
  return (
    <>
        <Topbar />
        <Header />
        <ScrollIcon />
        <Bot/>
        <LocationProvider>
        <Outlet />
        </LocationProvider>
        <Footer/>
        <CookieBanner/>
    </>
  )
}

function SuspenseLayout() {
  const[animation, setAnimation]= useState(mainVariant)
  return (
    <>
        <Topbar />
          <Header />
          <Suspense fallback={<Animation/>}>
          <ScrollIcon />
          <Bot/>
            <LocationProvider>
              <Outlet context={[animation, setAnimation]} />
            </LocationProvider>
          </Suspense>
          <Footer/>
          <CookieBanner/>
    </>
  )
}


function App() {
  const location = useLocation();
  const { auth } = useAuth();

  return (
    <>
      <Routes location={location} key={location.pathname}>
        <Route element={<SuspenseLayout />}>
        
            <Route path="/services" element={<Services />} />
            <Route path="/services/quotes/:preselecType?/:preselectService?" element={<Quotes />} />
            <Route path="/gas-safety-certificate" element={<Gas />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route exact path="/blog/:slug" element={<BlogSingle/>} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/sitemap" element={<SiteMap />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="careers/:careerId" element={<CareersIndividual />} />
            <Route exact path="/confirmation" element={<Confirmation />} />
            <Route exact path="/request-error" element={<Confirmation />} />

        </Route>
        <Route element={<NormalLayout/>}>
            <Route path="/" element={<HomePage />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/complaints" element={<Complaints />} />
            <Route exact path="/faqs" element={<Questions />} />
            <Route exact path="/privacy-policy" element={<Privacy />} />
            <Route path="*" element={<NotFound />} />

        </Route>
          <Route element={<Layout />}>
            <Route element={<PersistLogin />}>
                  <Route path="/login" element={
                    auth?.user ? <Navigate to="/dashboard" replace /> : <Login /> 
                } /> 
              <Route path="unauthorized" element={<Unauthorized />} />
                <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                  <Route path="/register" element={<Register />} />
                  <Route path="/users" element={<Users />} />
                </Route>
                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                  <Route path="/blogportal" element={<BlogPortal />} />
                  <Route path="/blogportal/addblog" element={<AddBlog />} />
                  <Route path="/blogportal/:slug" element={<EditBlogEntry />} />
                  <Route path="/careersportal" element={<CareersAdd />} />
                  <Route path="/careersportal/:jobId" element={<EditJobIntro />} /> 
                  <Route path="/careersportal/addjob" element={<AddJob />} /> 
                  <Route path="/careersportal/applicants" element={<Applicants />} /> 
                  <Route path="/careersportal/applicants/:slug" element={<ApplicantSingle />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/portal/quotes" element={<QuotesPortal />} />
                  <Route path="/portal/quotes/:slug" element={<QuoteSingle />} />
                  <Route path="/portal/complaints" element={<ComplaintsPortal />} />
                  <Route path="/portal/complaints/:slug" element={<ComplaintSingle />} />
                </Route>
            </Route>
            </Route>
      </Routes>
    </>
  );
}

export default App;
