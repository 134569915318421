import React, { useState } from 'react';
import {Collapse} from 'react-collapse';
import data from './data.json';
import SEO from '../../components/SEO';

import './faqs.css';

const Questions = () => {

    // Create a state to keep track of opened/closed FAQs
    const [openedFAQs, setOpenedFAQs] = useState({});

    // Function to toggle FAQ open/close state
    const toggleFAQ = (sectionIndex, questionIndex) => {
    const key = `${sectionIndex}-${questionIndex}`;
    // Toggle the opened state for the key
    setOpenedFAQs(prevState => ({
    ...prevState,
    [key]: !prevState[key],
    }));
    };

  return (
		<>
            <SEO title={"S3 Maintenance Services Faqs"} description={"Have a question? take a look at what other users have asked."}/>
			<div>
				<section id="page-title" className="background-grey-dark text-light">
					<div className="container">
						<div className="page-title">
							<h1>FAQs</h1>
							<span>Frequently Asked Questions</span>
						</div>
					</div>
				</section>
				<section>
					<div className="container">
						<div className="questions row">
							{data.faqs.map((section, sectionIndex) => (
								<div key={sectionIndex} className="section col-lg-6">
									<h3>
										{section.title} <small>({section.faqs.length})</small>
									</h3>
									<p>{section.description}</p>
									<div className="accordion toggle fancy radius clean">
										{section.faqs.map((question, questionIndex) => {
											const isOpen =
												openedFAQs[`${sectionIndex}-${questionIndex}`] || false;
											return (
												<div className="ac-item" key={questionIndex}>
													<h5
														className="ac-title"
														onClick={() =>
															toggleFAQ(sectionIndex, questionIndex)
														}
													>
														<i className="fa fa-question-circle"></i>
														{question.question}
													</h5>
													<Collapse isOpened={isOpen}>
														<div className="ac-content">{question.answer}</div>
													</Collapse>
												</div>
											);
										})}
									</div>
								</div>
							))}
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default Questions;
