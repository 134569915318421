import { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
// import { useNavigate, useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import toast from 'react-hot-toast';
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom"

import "../login.css";

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Users = () => {
const auth = useAuth(); // Get the logged-in user details
const axiosPrivate = useAxiosPrivate();
const errRef = useRef();

const [users, setUsers] = useState([]);
const [showModal, setShowModal] = useState(false);
const [userToDelete, setUserToDelete] = useState(null);
const [newPassword, setNewPassword] = useState(''); // New password for the selected user
const [userToChangePassword, setUserToChangePassword] = useState(null);
const [updated, setUpdated] = useState(0);
// const navigate = useNavigate();
// const location = useLocation();

const [color, setColor] = useState('danger');   

const [pwdFocus, setPwdFocus] = useState(false);
const [validPwd, setValidPwd] = useState(false);
const [matchPwd, setMatchPwd] = useState('');
const [validMatch, setValidMatch] = useState(false);
const [matchFocus, setMatchFocus] = useState(false);
const [errMsg, setErrMsg] = useState('');
const [deleteActive, setDeleteActive] = useState(false);
const navigate = useNavigate();

const register = () => navigate("/register");

useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUsers = async () => {
        try {
            const response = await axiosPrivate.get('/users', {
                signal: controller.signal
            });
            isMounted && setUsers(response.data);
            setUpdated(true);
            setErrMsg("Users List Updated");
            setColor("text-primary")
        } catch (err) {
            console.error(err);
            setColor("text-danger")
            setErrMsg('Error fetching users, refresh');        }
    }

    getUsers();

    return () => {
        isMounted = false;
        controller.abort();
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [updated])

useEffect(() => {
    setValidPwd(PWD_REGEX.test(newPassword));
    setValidMatch(newPassword === matchPwd);
}, [newPassword, matchPwd])

useEffect(() => {
    setErrMsg('');
}, [newPassword, matchPwd])


const deleteUser = async (userId) => {
try {
// Make request to delete the user
await axiosPrivate.delete(`/users/${userId}`);
    // Remove the user from state
    setUsers(users.filter(user => user.id !== userId));
    handleClose(); // Close the modal
    setUpdated(1);
    setErrMsg("User deleted successfully");
    setColor("text-success")
    toast.success('User deleted Successfully');
} catch (err) {
console.error(err);
    setColor("text-danger")
    toast.error('Error has occurred while deleting user');
    setErrMsg('An error occurred while deleting the user. Please try again.');
}
};

const handleChangePassword = async (userId) => {
    // Ensure that a new password has been entered
    if (!newPassword) return;
    try {
    await changeUserPassword(userId, newPassword);
    handleClose(); // Close the modal on success
    } catch (err) {
    console.error(err);
    // Optionally handle the error, such as showing a notification
    }
};

const changeUserPassword = async (userId, newPassword) => {
    try {
    // Make request to change the user's password
    await axiosPrivate.put(`/users/${userId}`, { newPassword });
        setErrMsg('Password updated successfully');
        setColor("text-success")
        toast.success('Password updated Successfully');
        setUpdated(2);
        } catch (err) {
        console.error(err);
        toast.error('Error has occurred while updating password');
        setColor("text-danger")
        setErrMsg('An error occurred while updating the password. Please try again.');
        // Handle error, such as by showing a notification
        }
};

const handleShow = (userId, action) => {
    if (action === 'delete') {
    setUserToDelete(userId);
    setDeleteActive(true);
    } else if (action === 'change-password') {
    setUserToChangePassword(userId);
    setNewPassword(''); // Reset password when opening the modal
    }
    setShowModal(true);
};

const handleClose = () => {
    setShowModal(false);
    setUserToDelete(null);
    setUserToChangePassword(null);
    setDeleteActive(null);
};

return (
    <section style={{padding:"4rem", paddingTop:"1rem"}} className="container-login">
            <div className="heading-text heading-gradient"><h2 style={{fontSize:"2.5rem"}}><span>Users List</span></h2></div>
                <div className="text-left m-b-40">
                    <button type="submit" className="btn btn-primary" onClick={register}>
                        Register a new user
                    </button>
                </div>
            <p ref={errRef} className={color+ " p-b-20"} aria-live="assertive">{errMsg}</p>
            {users.length ? (
            <table className="table table-hover">
                <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Username</th>
                    <th scope="col">Role</th>
                    <th scope="col">Delete user</th>
                    <th scope="col">Change password</th>
                </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                    <tr key={index}>
                        <th scope="row">{index+1}</th>
                        <td className="username"> {user.username}</td> 
                        <td className="username">
                        {user.roles.User === 2000 ? "Admin" : user.roles.User === 1999 ? "Content Manager" : user.roles.User}
                        </td>
                        <td>
                        {user.username !== 'jan' && user.username !== 'admin' && (
                            <Button className="btn btn-danger" onClick={() => handleShow(user._id, "delete")}>Delete User</Button>
                        )}
                    </td>
                    <td>
                        <Button className="btn btn-warning" onClick={() => handleShow(user._id, 'change-password')} 
                        disabled={ auth.user === 'jan' || auth.user === 'admin'}>Change Password</Button>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table> 
        ) : (
        <p>No users to display</p>
    )}
    {/* Confirm deletion modal */}
    <Modal centered show={showModal && deleteActive} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="danger" onClick={() => deleteUser(userToDelete)}>Delete</Button>
        </Modal.Footer>
    </Modal>
    <Modal centered show={showModal && userToChangePassword} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="input-group show-hide-password">
                    <input
                    type="password"
                    id="password"
                    className="form-control"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                    placeholder="Enter password"
                    />
                </div>
                <div id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                    <p className="text-muted"> 
                    8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character.<br />
                    Allowed special characters: ! @ # $ %
                    </p>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="confirm_pwd">Confirm Password</label>
                <input
                type="password"
                id="confirm_pwd"
                className="form-control"
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
                required
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
                placeholder="Confirm your password"
                />
                <div id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                    <p className="text-muted"> 
                    Must match the first password input field.
                    </p>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="primary" onClick={() => handleChangePassword(userToChangePassword)}>Save Password</Button>
        </Modal.Footer>
    </Modal>
    </section>
    );
};

export default Users;