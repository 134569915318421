import React from 'react';

const ShareButton = ({ platform, url, title, children }) => {

    const shareLink = () => {
        switch (platform) {
            case 'facebook':
                window.open(`https://www.facebook.com/sharer/sharer.php?u=&t=${encodeURIComponent(title)}`, '_blank');
                break;
            case 'twitter':
                window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`, '_blank');
                break;
            case 'instagram':
                window.open(`https://www.instagram.com/?url=${encodeURIComponent(url)}`, '_blank');
                break;
            case 'email':
                window.open(`mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(url)}`);
                break;
            default:
                break;
        }
    };

    let buttonClassName = '';

    switch (platform) {
        case 'facebook':
            buttonClassName = 'btn btn-xs btn-slide btn-facebook';
            break;
        case 'twitter':
            buttonClassName = 'btn btn-xs btn-slide btn-twitter';
            break;
        case 'instagram':
            buttonClassName = 'btn btn-xs btn-slide btn-instagram';
            break;
        case 'email':
            buttonClassName = 'btn btn-xs btn-slide btn-googleplus';
            break;
        default:
            break;
    }

    return (
        <button className={buttonClassName} onClick={shareLink}>
            {children} 
        </button>
    );
};

export default ShareButton;