import { useRef, useState, useEffect } from "react";
import axios from '../../../hooks/api';
import { Modal, Button } from 'react-bootstrap'; // Import React Bootstrap components
import toast from 'react-hot-toast';

const ROLES = {
    'Manager': 1999,
    'Admin': 2000,
    };
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';

const Register = () => {
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [role, setRole] = useState('Manager'); // Role is set to 'Blog' by default
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);


    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const confirmRoleAndSubmit = async () => {
    handleCloseModal();
    if (role === 'Admin') {
    // You can move the submit logic here or call `handleSubmit` directly
    handleSubmit();
    }
    };

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    const handleSubmit = async (e) => {
        e && e.preventDefault();
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);
        const v2 = PWD_REGEX.test(pwd);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            const response = await axios.post(REGISTER_URL,
                JSON.stringify({ user, pwd, role: ROLES[role] }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            // TODO: remove console.logs before deployment
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response))
            setSuccess(true);
            toast.success('User registered Successfully');
            //clear state and controlled inputs
            setUser('');
            setPwd('');
            setMatchPwd('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
                toast.error('Error: No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
                toast.error('Error: Username Taken');
            } else {
                toast.error('Error: Registration Failed');
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
        }
    }

    return (
        <>
            {success ? (
                <section className="container-login w-100 px-3 px-sm-5 px-xl-7">
                    <h1 style={{margin:"auto", justifyContent:"center", alignContent:"center", display:"flex"}}>Success!</h1>
                    <button onClick={()=> setSuccess(false)} style={{margin:"auto", height:"100%", justifyContent:"center", alignContent:"center", display:"flex"}}>Register another user</button>
                </section>
            ) : (
                <section style={{padding:"2rem"}} className="container-login">
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <div className="heading-text heading-gradient"><h2 style={{fontSize:"2.5rem"}}><span>Register a new user</span></h2></div>
                <form id="form1" className="form-validate mt-5" onSubmit={handleSubmit}>
                
                <div className="form-group">
                <label htmlFor="role">Role</label>
                <select
                id="role"
                className="form-control"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                >
                {Object.keys(ROLES).map(r => (
                <option key={r} value={r}>{r}</option>
                ))}
                </select>
                </div>
                
                <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                type="text"
                id="username"
                className="form-control"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
                aria-invalid={validName ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setUserFocus(true)}
                onBlur={() => setUserFocus(false)}
                placeholder="Enter username"
                />
                <div id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                    <p className="text-muted"> 
                    The user name must be 4 to 24 characters. Must begin with a letter. Letters, numbers, underscores, hyphens allowed.
                    </p>
                </div>
                </div>

                <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="input-group show-hide-password">
                <input
                type="password"
                id="password"
                className="form-control"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
                placeholder="Enter password"
                />
                </div>
                <div id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                    <p className="text-muted"> 
                    8 to 24 characters. Must include uppercase and lowercase letters, a number and a special character.<br />
                    Allowed special characters: ! @ # $ %
                    </p>
                </div>
                </div>

                <div className="form-group">
                <label htmlFor="confirm_pwd">Confirm Password</label>
                <input
                type="password"
                id="confirm_pwd"
                className="form-control"
                onChange={(e) => setMatchPwd(e.target.value)}
                value={matchPwd}
                required
                aria-invalid={validMatch ? "false" : "true"}
                aria-describedby="confirmnote"
                onFocus={() => setMatchFocus(true)}
                onBlur={() => setMatchFocus(false)}
                placeholder="Confirm your password"
                />
                <div id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                    <p className="text-muted"> 
                    Must match the first password input field.
                    </p>
                </div>
                </div>

                <button
                type="button" // Change to "button" so it doesn't submit the form
                className="btn btn-primary"
                onClick={() => role === 'Admin' ? handleShowModal() : handleSubmit()}
                disabled={!validName || !validPwd || !validMatch}
                >
                Register
                </button>                
                
                </form>

                <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                <Modal.Title>Confirm Role Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Are you sure you want to register as an Admin?
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
                </Button>
                <Button variant="primary" onClick={confirmRoleAndSubmit}>
                Confirm
                </Button>
                </Modal.Footer>
                </Modal>

                </section>
            )}
        </>
    )
}

export default Register