import React, { useState, useEffect } from 'react';
import './selector.css';

const Selector = ({ options, onChange, required = false, value, label = 'default value' }) => {
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    if (value) setSelectedOption(value);
  }, [value]);

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div>
      <select
        value={selectedOption}
        onChange={handleSelectionChange}
        className="form-select"
        required={required}
        aria-label={label}
      >
        <option value="" disabled>Select an option</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Selector;
