import { LinkComponent } from "./linkComponent"

const NavToServices = () => <LinkComponent to='/services' text='Go to Services'/>;
const NavToQuotes = () => <LinkComponent to='/services/quotes' text='Get your Quote'/>;
const NavToProjects = () => <LinkComponent to='/projects' text='Go to Projects'/>;
const NavToClients = () => <LinkComponent to='/clients' text='Meet our Clients'/>;
const NavToAbout = () => <LinkComponent to='/about' text='Learn about us'/>;
const NavToBlog = () => <LinkComponent to='/blog' text='Visit our blog'/>;
const NavToContact = () => <LinkComponent to='/contact' text='Contact us'/>;
const NavToPricing = () => <LinkComponent to='/pricing' text='Click here to see our pricing'/>;
const NavToSitemap = () => <LinkComponent to='/sitemap' text='Click here to view the sitemap'/>;
const NavToTerms = () => <LinkComponent to='/terms' text='Click here to read our terms'/>;
const NavToCareers = () => <LinkComponent to='/careers' text='View our careers opportunities'/>;
const NavToComplaints = () => <LinkComponent to='/complaints' text='Submit a complaint'/>;
const NavToFaqs = () => <LinkComponent to='/faqs' text='Click here to visit our FAQs'/>;
const NavToPrivacy = () => <LinkComponent to='/privacy-policy' text='Click here to read our privacy policy'/>;


export {
  NavToServices,
  NavToQuotes,
  NavToProjects,
  NavToClients,
  NavToAbout,
  NavToBlog,
  NavToContact,
  NavToPricing,
  NavToSitemap,
  NavToTerms,
  NavToCareers,
  NavToComplaints,
  NavToFaqs,
  NavToPrivacy
}