import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SortableTable from '../Components/sort.jsx';

const ComplaintsPortal = () => {
    const [complaints, setComplaints] = useState([]);
    const [filteredComplaints, setFilteredComplaints] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const axios = useAxiosPrivate();

    const sortComplaints = (field, ascending) => {
        const sortedData = [...filteredComplaints];

        if (typeof sortedData[0][field] === 'number') {
            sortedData.sort((a, b) => ascending ? a[field] - b[field] : b[field] - a[field]);
        } else if (typeof sortedData[0][field] === 'string') {
            sortedData.sort((a, b) => {
                const valueA = a[field].toLowerCase();
                const valueB = b[field].toLowerCase();
                return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
            });
        } else if (sortedData[0][field] instanceof Date) {
            sortedData.sort((a, b) => ascending ? new Date(a[field]) - new Date(b[field]) : new Date(b[field]) - new Date(a[field]));
        }

        setFilteredComplaints(sortedData);
    };

    const TABLE_CONFIG = [
        { key: 'name', name: 'Name' },
        { key: 'email', name: 'Email' },
        { key: 'phone', name: 'Phone' },
        { key: 'message', name: 'Message' },
        { key: 'createdAt', name: 'Date Submitted' },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/complaint');
                setComplaints(response.data);
            } catch (error) {
                console.error('Error fetching complaints data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const searchValue = searchTerm.toLowerCase();
        const newFilteredComplaints = complaints.filter((complaint) => (
            complaint.name.toLowerCase().includes(searchValue) ||
            complaint.email.toLowerCase().includes(searchValue) ||
            complaint.phone.toLowerCase().includes(searchValue) ||
            complaint.message.toLowerCase().includes(searchValue) ||
            new Date(complaint.createdAt).toLocaleDateString().toLowerCase().includes(searchValue)
        ));
        setFilteredComplaints(newFilteredComplaints);
    }, [searchTerm, complaints]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <section style={{ padding: "4rem", paddingTop: "1rem" }} className="container-login">
            <div className="heading-text heading-gradient">
                <h2 style={{ fontSize: "2.5rem" }}><span>Complaints</span></h2>
            </div>
            <div className="form-group">
                <input
                    className='form-control'
                    type="text"
                    placeholder="Search complaints..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            {filteredComplaints.length ? (
                <SortableTable
                    data={filteredComplaints}
                    config={TABLE_CONFIG}
                    sortTable={sortComplaints}
                />
            ) : (
                <p style={{ paddingTop: "5rem" }}>No complaints to display</p>
            )}
        </section>
    );
};

export default ComplaintsPortal;