import React, { useState, useEffect } from "react";

import { LinkComponent } from "./linkComponent";

const DisplayMultipleLinks = (mode = 'default') => {

  const defaultLinks = [
    { to: '/services', text: 'Go to Services'},
    { to: '/services/quotes', text: 'Get your Quote'},
    { to: '/projects', text: 'Go to Projects'},
    { to: '/clients', text: 'Meet our Clients'},
    { to: '/about', text: 'Learn about us'},
  ]

  return(
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      {
        defaultLinks.map((link, idx) => (
          <LinkComponent key={`link-component-${link.text}-${idx}`} to={link.to} text={link.text} delay={idx}/>
        ))
      }
    </div>
  )
}

const DisplayRandomLinks = (mode = 'default') => {

  const [displayLinks, setDisplayLinks] = useState([])

  const allLinks = [
    { to: '/services', text: 'Go to Services'},
    { to: '/services/quotes', text: 'Get your Quote'},
    { to: '/projects', text: 'Go to Projects'},
    { to: '/clients', text: 'Meet our Clients'},
    { to: '/about', text: 'Learn about us'},
    { to: '/blog', text: 'Visit our blog'},
    { to: '/contact', text: 'Contact us'},
    { to: '/pricing', text: 'Click here to see our pricing'},
    { to: '/sitemap', text: 'Click here to view the sitemap'},
    { to: '/terms', text: 'Click here to read our terms'},
    { to: '/careers', text: 'View our careers opportunities'},
    { to: '/complaints', text: 'Submit a complaint'},
    { to: '/faqs', text: 'Click here to visit our FAQs'},
    { to: '/privacy-policy', text: 'Click here to read our privacy policy'},
  ];

  const reandomizeLinks = (arr) => {
    const shuffled = arr.sort(() => Math.random() - 0.5);
    
  return shuffled.slice(0, 5);
  }
  useEffect(() => {
    setDisplayLinks(reandomizeLinks(allLinks))
  },[allLinks])

  return(
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      {
        displayLinks.map((link, idx) => (
          <LinkComponent key={`link-component-${link.text}-${idx}`} to={link.to} text={link.text} delay={idx}/>
        ))
      }
    </div>
  )
}

export {DisplayMultipleLinks, DisplayRandomLinks}