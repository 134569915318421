import React from 'react';
import { NavLink } from '../../components/NavLink/navLink';
import './terms.css'

const Terms = () => {

  return (
        <section>
            <div className="container">
            <div className="heading-text heading-line">
            <h1 className='terms-title'>S3 Maintenance Terms & Conditions</h1>
            </div>
            <div className="row">
            <div className="col-lg-12">
            <h4>About S3 Maintenance Ltd</h4>
            <p>
            S3 Maintenance Ltd is a UK-based company specializing in a wide range of maintenance services. Our registration details are as follows:
            </p>
            <p>
            Registered in England and Wales No.13658357<br/>
            Registered Office: 400a Finchley Road, London, NW2 2HR, UK<br/>
            UK VAT No.
            </p>
            <p>
            You can reach us through our website's contact page in this <NavLink to='/contact'>Link</NavLink>.
            </p>

            <h4>Service Users Agreement</h4>
            <p>
            By using our services, you confirm that:
            </p>
            <ul>
            <li>You have the right and capacity to enter into legally binding contracts.</li>
            <li>You are at least 18 years old.</li>
            <li>You reside and are accessing our services from the UK or another European Union member state.</li>
            </ul>
            <p>
            As we primarily cater to UK customers, please be aware that additional charges may apply for services requested from outside the UK.
            </p>

            <h4>Service Description and Obligations</h4>
            <p>
            We take care to accurately describe our maintenance services. Still, because your specific maintenance requirements may vary, slight differences may occur between our general service descriptions and the services you receive. You are responsible for providing complete and accurate details concerning the services you require.
            </p>

            <h4>Service Request and Contract Formation</h4>
            <p>
            Service requests are subject to acceptance based on our available resources. Upon submitting a request, you must provide truthful and complete information. Contractual obligations begin once we send you a confirmation email acknowledging your order.
            </p>

            <h4>Consumer Protections</h4>
            <p>
            This section pertains solely to our services that are contracted for personal, non-commercial use:
            </p>
            <ul>
            <li>Consumers have the right to cancel a contract within 14 days, barring any immediate service request within this period, wherein the right to cancel is waived.</li>
            </ul>
            <p>
            Further details regarding your consumer rights will be included in your order confirmation.
            </p>

            <h4>Cancellations & Refunds</h4>
            <p>
            Please inform us promptly if you wish to cancel a service. Terms pertaining to our refund and cancellation policy will apply, which may include fees dependent on the time of cancellation or if certain service terms are unmet.
            </p>

            <h4>Our Accountability</h4>
            <p>
            Our services come with the guarantee to be delivered with reasonable skill and care. Our liability for any losses due to breach of our agreement is limited to the service fee and any direct damages that can be demonstrated. We are not responsible for indirect losses such as secondary costs, loss of business, or lost opportunities.
            </p>

            <h4>Website Use Policy</h4>
            <p>
            When using our website and services, you must comply with all relevant regulations. The content on our website is the property of S3 Maintenance. You agree not to copy, sell, resell, or exploit any part of the website content without our express written consent.
            </p>

            <h4>Handling of Personal Information</h4>
            <p>
            The management of your personal information is in accordance with our Privacy Policy, which is stated above.
            </p>

            <h3>Modifications to Terms & Conditions</h3>
            <p>
            We reserve the right to modify these Terms and Conditions at any time. Changes will be posted on our website or sent via email to users.
            </p>

            <h4>Governing Law & Jurisdiction</h4>
            <p>
            These Terms and Conditions are governed by English law, and any disputes will be subject to the exclusive jurisdiction of the English courts.
            </p>

            <h3>Contacting S3 Maintenance</h3>
            <p>
            If you have questions or concerns regarding these Terms & Conditions, please contact us at:
            </p>
            <p>
            S3 Maintenance Ltd<br/>
            400a Finchley Road<br/>
            London, NW2 2HR<br/>
            Email: info@s3maintenance.co.uk<br/>
            Phone: +44 20 7794 8159
            </p>
            </div>
            </div>
            </div>
        </section>
  );
};

export default Terms;
