import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from './hooks/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import SEO from './components/SEO';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

  root.render(
    <BrowserRouter>
    <SEO title="S3 Maintenance Service" description="S3 Maintenance is a property maintenance company operating in the UK and Ireland Nationwide. We offer a range of services including plumbing, electrical, and general maintenance." url="" />
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
