import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import "../login.css";
import SpinnerLoading from '../../../components/spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const QuoteSingle = () => {
    const axiosPrivate = useAxiosPrivate();
    const { state } = useLocation();
    const quote = state.item;
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData] = useState({
        name: quote.name,
        company: quote.company,
        email: quote.email,
        phone: quote.phone,
        address: quote.address,
        reference: quote.reference,
        referenceOther: quote.referenceOther,
        message: quote.message,
        service: quote.service,
        serviceOther: quote.serviceOther,
        property: quote.property,
        propertyOther: quote.propertyOther,
        location: quote.location,
        locationOther: quote.locationOther,
        subscription: quote.subscription ? 'Yes' : 'No',
        date: quote.date,
        budget: quote.budget,
        termsAndConditions: quote.termsAndConditions ? 'Agreed' : 'Not Agreed'
    });

    const handleShow = () => {
        setShowModal(true);
    };
    
    const handleClose = () => {
        setShowModal(false);
    };
    const handleFileDownload = async () => {
        setIsLoading(true);
        if (!quote.file) {
            toast.error('No file associated with this quote');
            setIsLoading(false);
            return;
        }
        try {
            const response = await axiosPrivate.get(`/quotes/download/${quote._id}`);
            const downloadUrl = response.data.url; // The backend should send the pre-signed URL 
    
            // Download directly using the link
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', quote.file.fileName); 
            link.target = '_blank'; // Open in a new window/tab
            document.body.appendChild(link); 
            link.click(); 
            link.remove(); 
            toast.success('CV downloading...');
            setIsLoading(false);
        } catch (error) {
            toast.error('Failed to download file');
            setIsLoading(false);
        }    };

        const handleFileDelete = async () => {
            try {
            // Make request to delete the user
            await axiosPrivate.put(`/quotes/delete/${quote._id}`);
                // Remove the user from state
                toast.success('User deleted Successfully');
                navigate(-1);
            } catch (err) {
            console.error(err);
                toast.error('Error has occurred while deleting user');
            }
            };

        function openMailto() {
            const recipient = quote.email;
            const subject = "Your Quote for " + quote.service + " services";
            const body = "Dear " + quote.name + ",\n\n" + "Thank you for your interest in our " + quote.service + " service. We have attached a quote for your review. Please let us know if you have any questions or need further information.\n\n" + "Best regards,\n\n" + "S3 Maintenance Services Team";
            
            const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            
            window.location.href = mailtoLink;
            }

    return (
        <>
            <div className="container-login applicant p-r-20">
                <div className="card-header">
                    <div className="heading-text heading-gradient">
                        <h2 style={{ fontSize: "2.5rem" }}>
                            <span>Quote information</span>
                        </h2>
                    </div>
                    <p className="text-primary">Quote's details below, download the attached file if available</p>
                </div>
                {Object.entries(formData).map(([key, value]) => (
                    <div className="card-header" key={key}>
                        <h4 className='text-uppercase'>{key.replace(/([A-Z])/g, ' $1')}</h4>
                        <p>{value}</p>
                    </div>
                ))}
            </div>
            <Modal centered show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this quote?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleFileDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>
            {isLoading ? <SpinnerLoading /> : (
                <div className='buttons-quotes'>
                <>
                    {quote.file && quote.file.fileName ? (
                        <button
                            style={{ height: "50px", width: "200px", margin: "20px" }}
                            className="btn btn-primary" onClick={handleFileDownload}>
                            <i className="fa fa-download"
                                style={{ paddingRight: "10px" }}></i>
                            Download File
                        </button>
                    ) : (
                        <button
                            style={{ height: "50px", width: "200px", margin: "20px" }}
                            className="btn btn-primary disabled"
                            disabled>
                            No File Attached
                        </button>
                    )}
                        <button
                            style={{ height: "50px", width: "200px", margin: "20px" }}
                            className="btn btn-danger" onClick={() => handleShow()}>
                            Delete quote
                        </button>
                        <button
                            style={{ height: "50px", width: "200px", margin: "20px" }}
                            className="btn btn-secondary"  onClick={openMailto}>
                            Send Email 
                        </button>
                </>
                </div>
            )}
        </>
    );
};

export default QuoteSingle;
