import React from "react";
import SEO from "../../components/SEO";

const d = new Date();
let year = d.getFullYear();

const Privacy = () => {
	return (
		<>
			<SEO
				title={"S3 Maintenance Services Privacy policy"}
				description={"S3 Maintenance Services webpage privacy policy"}
			/>
			<section>
				<div className="container">
					<div className="heading-text heading-line">
						<h4>S3 Maintenance Privacy Policy</h4>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<h1>
								<small>Last Updated: {year}</small>
							</h1>
							<p>
								Welcome to S3 Maintenance's Privacy Policy. We take our
								responsibility to protect the personal information provided to
								us seriously. This policy explains how we collect, manage, use,
								and protect your personal information. By using our website, you
								agree to the collection, use, and sharing of your personal data
								as described in this policy.
							</p>

							<h2>Who we are</h2>
							<p>
								S3 Maintenance is a maintenance service company registered in
								the United Kingdom, providing a variety of services to our
								customers. This policy applies to S3 Maintenance and its
								affiliated companies.
							</p>

							<h3>Contact Information:</h3>
							<p>
								S3 Maintenance
								<br />
								400a Finchley Road
								<br />
								NW2 2HR
								<br />
								Email: info@s3maintenance.co.uk
								<br />
								Phone: +44 20 7794 8159
							</p>

							<h3>Information We Collect</h3>
							<p>
								S3 Maintenance may collect and process the following data about
								you:
							</p>
							<ul>
								<li>
									<strong>Cookies and Usage Data:</strong> When you visit our
									website, we may collect information about your visit,
									including your IP address, browser type, referral source, page
									views, and website navigation paths. We use cookies and
									similar tracking technologies to track activity on our website
									and hold certain information. Cookies are files with a small
									amount of data which may include an anonymous unique
									identifier.
								</li>
								<li>
									<strong>Personal Information:</strong> We may ask you to
									provide us with certain personally identifiable information
									that can be used to contact or identify you. This may include
									but is not limited to your name, email address, telephone
									number, or postal address.
								</li>
							</ul>

							<h3>How We Use Your Information</h3>
							<p>
								The information we collect is used for the following purposes:
							</p>
							<ul>
								<li>To provide you with the services you've requested.</li>
								<li>To improve our website by understanding how it's used.</li>
								<li>To maintain the safety and security of our website.</li>
								<li>To contact you about your account and services.</li>
								<li>To comply with the law and our legal obligations.</li>
							</ul>

							<h4>Cookie Policy</h4>
							<p>
								We use cookies and similar tracking technologies to track the
								activity on our service and hold certain information. Cookies we
								use:
							</p>
							<ul>
								<li>
									<strong>Session Cookies:</strong> These are used to operate
									our service.
								</li>
								<li>
									<strong>Preference Cookies:</strong> These are used to
									remember your preferences and various settings.
								</li>
								<li>
									<strong>Security Cookies:</strong> These are used for security
									purposes.
								</li>
								<li>
									<strong>Analytics Cookies:</strong> These are used to track
									the usage of our website.
								</li>
							</ul>
							<p>
								You have the option to refuse all cookies or to indicate when a
								cookie is being sent. However, if you do not accept cookies, you
								may not be able to use some parts of our service.
							</p>

							<h3>Data Security</h3>
							<p>
								We are committed to ensuring that your information is secure and
								have put in place suitable physical, electronic, and managerial
								procedures to prevent unauthorized access or disclosure and
								safeguard the information we collect online.
							</p>

							<h3>Data Retention</h3>
							<p>
								We will only retain your personal information for as long as
								necessary to fulfill the purposes we collected it for, including
								for the purposes of satisfying any legal, accounting, or
								reporting requirements.
							</p>

							<h3>Your Rights</h3>
							<p>
								Under data protection laws in the UK, you have rights in
								relation to your personal data:
							</p>
							<ul>
								<li>The right to access information held about you.</li>
								<li>
									The right to request correction of any incorrect or incomplete
									data.
								</li>
								<li>
									The right to request deletion or removal of personal data
									where there is no good reason for us continuing to process it.
								</li>
								<li>
									The right to object to processing of your data where we are
									relying on a legitimate interest.
								</li>
								<li>
									The right to request the restriction of processing of your
									personal data.
								</li>
							</ul>
							<p>
								To exercise any of these rights, please contact us using the
								contact details provided above.
							</p>

							<h2>Changes to Our Privacy Policy</h2>
							<p>
								We may update our Privacy Policy from time to time. We will
								notify you of any changes by posting the new Privacy Policy on
								this page and updating the "Last Updated" date at the top of
								this Privacy Policy.
							</p>

							<h3>Contact Us</h3>
							<p>
								If you have any questions about our Privacy Policy, the data we
								hold on you, or would like to exercise one of your data
								protection rights, please do not hesitate to contact us.
							</p>
							<p>
								S3 Maintenance
								<br />
								400a Finchley Road
								<br />
								NW2 2HR
								<br />
								Email: info@s3maintenance.co.uk
							</p>
							<p>
								Thank you for choosing S3 Maintenance. Your privacy is important
								to us.
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Privacy;
