import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "../../hooks/api";
import "./blog.css";
import SEO from "../../components/SEO";
import ShareButton from "../../components/ShareLink";
import SpinnerLoading from "../../components/spinner";

const BlogSingle = () => {
	const siteUrl = "https://www.s3maintenance.co.uk/";
	const { slug } = useParams();
	const [selectedType, setSelectedType] = useState("");
	const [data, setData] = useState([]);
	const [blog, setBlogData] = useState();
	const [prevSlug, setPrevSlug] = useState("");
	const [nextSlug, setNextSlug] = useState("");
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get("/fe/blog");
				setBlogData(response.data.find((entry) => entry._id === slug));
				setData(response.data);

				const currentIndex = response.data.findIndex(
					(entry) => entry._id === slug
				);
				const prevIndex =
					(currentIndex - 1 + response.data.length) % response.data.length;
				const nextIndex = (currentIndex + 1) % response.data.length;

				setPrevSlug(response.data[prevIndex]._id);
				setNextSlug(response.data[nextIndex]._id);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching careers data:", error);
				setLoading(false);
			}
		};
		fetchData();
	}, [slug]);

	const handleClick = (type) => {
		setSelectedType(type === "all" ? "" : type);
	};

	const handleExcludePost = (postId) => {
		if (postId === slug) {
			return false;
		}
		return true;
	};

	console.log(blog);

	return (
		<>
			<SEO
				title={blog?.title}
				description={`We have the latest updates in ${blog?.title}`}
				url={`blog/${slug}`}
				image={blog?.images}
			/>
			<div className="container">
				<section
					id="page-content"
					className="sidebar-right blog-single-container"
				>
					<div className="">
						{loading ? (
							<SpinnerLoading />
						) : (
							<div className="row">
								<div className="content col-lg-9">
									<div id="blog" className="single-post">
										<div className="post-item">
											<div className="post-item-wrap ">
												<div className="post-item-description">
													<h1 className="blogs-title">{blog?.title}</h1>
													<div className="post-meta">
														<span className="post-meta-date">
															<i className="fa fa-calendar-o" />
															{new Date(blog?.datePosted).toLocaleDateString(
																"en-US",
																{
																	year: "numeric",
																	month: "long",
																	day: "numeric",
																}
															)}
														</span>
														<span className="post-meta-category">
															<span>
																<i className="fa fa-tag" />
																{blog?.type}
															</span>
														</span>
														<div className="post-meta-share">
															<ShareButton
																platform="facebook"
																url={`${siteUrl}blog/${slug}`}
																title={blog?.title}
															>
																<i className="fab fa-facebook-f" />
																<span>Facebook</span>
															</ShareButton>
															<ShareButton
																platform="twitter"
																url={`${siteUrl}blog/${slug}`}
																title={blog?.title}
															>
																<i className="fab fa-twitter" />
																<span>Twitter</span>
															</ShareButton>
															<ShareButton
																platform="instagram"
																url={`${siteUrl}blog/${slug}`}
																title={blog?.title}
															>
																<i className="fab fa-instagram" />
																<span>Instragram</span>
															</ShareButton>
															<ShareButton
																platform="email"
																url={`${siteUrl}blog/${slug}`}
																title={blog?.title}
															>
																<i className="icon-mail" />
																<span>Email</span>
															</ShareButton>
														</div>
													</div>
													<pre className="blog-single-text">{blog?.body}</pre>
													<img alt={blog?.title} src={blog?.images} />

													<div style={{ marginTop: "2rem" }}>
														{blog?.links && (
															<div className="reference-links">
																<h4 className="links-title">Reference Links</h4>
																<ul className="links-blog">
																	{blog.links.map((link, index) => (
																		<span key={index} className="hover-link">
																			<a
																				className="link-blog"
																				href={link}
																				style={{
																					paddingBottom: "2rem",
																					lineHeight: "2.5rem",
																					whiteSpace: "pre-wrap",
																					overflowWrap: "break-word",
																					wordWrap: "break-word",
																					fontFamily: "Poppins",
																					fontSize: "14px",
																					fontWeight: "400",
																					fontStyle: "normal",
																					color: "#333",
																				}}
																			>
																				{link}
																			</a>
																		</span>
																	))}
																</ul>
															</div>
														)}
													</div>
												</div>
												<div className="post-navigation">
													{prevSlug && (
														<Link
															to={`/blog/${prevSlug}`}
															className="post-prev"
														>
															<div className="post-prev-title">
																<span>Previous Post</span>
																Navigate to the previous post
															</div>
														</Link>
													)}
													<Link to="/blog" className="post-all">
														<i className="icon-grid" />
													</Link>
													{nextSlug && (
														<Link
															to={`/blog/${nextSlug}`}
															className="post-next"
														>
															<div className="post-next-title">
																<span>Next Post</span>
																Navigate to the next post
															</div>
														</Link>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="sidebar sticky-sidebar col-lg-3">
									<div className="widget">
										<div className="tabs">
											<ul
												className="nav nav-tabs"
												id="tabs-posts"
												role="tablist"
											>
												<li className="nav-item">
													<p
														className="nav-link active"
														id="home-tab"
														aria-controls="popular"
														aria-selected="true"
													>
														Other Posts
													</p>
												</li>
											</ul>
											<div className="tab-content" id="tabs-posts-content">
												<div
													className="tab-pane fade show active"
													id="popular"
													role="tabpanel"
													aria-labelledby="popular-tab"
												>
													<div className="post-thumbnail-list">
														{data
															?.filter(
																(data) =>
																	selectedType === "" ||
																	data.type === selectedType
															)
															.filter((data) => handleExcludePost(data._id))
															.slice(0, 4)
															.map((data, index) => (
																<div
																	className="post-thumbnail-entry"
																	key={data._id}
																>
																	<img
																		alt={`${data.title}`}
																		src={data.images}
																	/>
																	<div className="post-thumbnail-content">
																		<Link to={`/blog/${data?._id}`}>
																			{data.title}
																		</Link>
																		<span className="post-date">
																			<i className="icon-clock" />
																			{new Date(
																				data.datePosted
																			).toLocaleDateString("en-US", {
																				year: "numeric",
																				month: "long",
																				day: "numeric",
																			})}
																		</span>
																		<span className="post-category">
																			<i className="icon-target" />
																			{data.type}
																		</span>
																	</div>
																</div>
															))}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="widget  widget-tags">
										<h4 className="widget-title">Tags</h4>
										<div className="tags">
										<button className="tags-element" 
												onClick={(e) => {
													e.preventDefault();
													handleClick("all");
												}}
											>
												All types
											</button>
											{data?.map((item, index) => (
												<button className="tags-element" 
													key={index}
													onClick={(e) => {
														e.preventDefault();
														handleClick(item.type);
													}}
												>
													{item.type}
												</button>
											))}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</section>
			</div>
		</>
	);
};

export default BlogSingle;
