import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import toast from 'react-hot-toast';

const CareersAdd = () => {
const [careers, setCareers] = useState([]);
const [showModal, setShowModal] = useState(false);
const [entryToDelete, setEntryToDelete] = useState(null);
const axios = useAxiosPrivate();

const navigate = useNavigate();

// Fetch careers info from the server on component mount
useEffect(() => {
const fetchData = async () => {
    try {
        const response = await axios.get('/careers'); // Adjust the URL to your API endpoint
        setCareers(response.data);
        const data = await response.data;
    } catch (error) {
        console.error('Error fetching careers data:', error);
    }
    };
    fetchData();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const handleShow = (blogId) => {
    setEntryToDelete(blogId);
    setShowModal(true);
};

const handleClose = () => {
    setShowModal(false);
    setEntryToDelete(null);
};

// Handle deleting a career
const handleDelete = async (careerId) => {
    try {
    await axios.put(`/careers/delete/${careerId}`); // Adjust the URL to your API endpoint
    // Update the local state without the deleted career
        setCareers(careers.filter(career => career._id !== careerId));
        toast.success('Job deleted Successfully');
        handleClose();
    } catch (error) {
        console.error('Error deleting career:', error);
        toast.error('Error deleting job, refresh and try again.');
        handleClose();
    }
};

return (
    <section style={{padding:"4rem", paddingTop:"1rem"}} className="container-login">
        <div className="heading-text heading-gradient"><h2 style={{fontSize:"2.5rem"}}><span>Listed Jobs in careers</span></h2></div>
            <Link to={"/careersportal/addjob"}> 
                <button className="btn btn-outline btn-sm btn-roundeded btn-reveal">
                <span>Add new Job</span>
                <i className="icon-chevron-right"></i></button>
            </Link>
            <Link className='right' to={"/careersportal/applicants"}> 
                <button className="btn btn-primary btn-sm btn-roundeded btn-reveal">
                <span>See applicants</span>
                <i className="icon-chevron-right"></i></button>
            </Link>
            {careers.length ? (
                        <table className="m-t-40 table table-hover">
                            <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Job Title</th>
                                <th scope="col">Date Posted</th>
                                <th scope="col">Edit user</th>
                                <th scope="col">Delete user</th>
                            </tr>
                        </thead>
                        <tbody>
                        {careers.map((career, index) => (
                                    <tr key={index}>
                                    <th scope="row">{index+1}</th>
                                    <td className="job-title"> {career.position.title}</td> 
                                    <td className="date-posted"> {new Date(career.datePosted).toLocaleDateString()}</td> 
                                    {/* <p className="text-justify">{career.position.overview}</p> */}
                                    <td>
                                        <button id={career._id} onClick={(e) => {
                                            e.preventDefault();
                                            const jobSlug = e.target.id;
                                            navigate(`/careersportal/${jobSlug}`,
                                        { state: { career } }
                                        )} 
                                        }
                                        className="btn btn-light">
                                                    Edit
                                        </button>
                                    </td>
                                    <td>
                                        <button className="btn btn-danger" onClick={() => handleShow(career._id)}>
                                            <span>Delete</span>
                                        </button>
                                        </td>
                                    </tr>
                        ))}
                    </tbody>
                    <Modal centered show={showModal} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Deletion</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete this job entry?</Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                            <Button variant="danger" onClick={() => handleDelete(entryToDelete)}>Delete</Button>
                        </Modal.Footer>
                    </Modal>
                </table>
        ) : (
        <p style={{paddingTop:"5rem"}}>No Jobs to display</p>
        )}
    </section>
    );
};

export default CareersAdd;