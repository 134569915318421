import React, { useEffect, useState } from 'react';
import VideoPlayer from '../../components/VideoPlayer';
import introVideo from '../../images/intro-video.mp4';
import { motion } from "framer-motion";
import { Collapse } from 'react-collapse';
import Values from '../../components/Values/values.jsx';
import Counter from '../../components/Counter/counters';
import Testimonials from '../../components/Testimonials/testimonials';
import data from '../../components/data.json';
import WhatWeDo from '../../components/whatwedo.jsx';
// import Team from '../../components/team.jsx';
import Photos from '../../components/PhotoAlbum/photoalbum';
import { NavLink } from '../../components/NavLink/navLink';
import { useInView } from 'react-intersection-observer';
import { useLocation } from "react-router-dom";

import "./home.css";
import SEO from '../../components/SEO.jsx';

const fromLeftVariants = {
  offscreen: {
    x: -100,
    opacity: 0
  },
  onscreen: {
    x: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1.4
    }
  }
};

const textBlocks = data.textBlocks;
const section = data.section;
const clients = data.clients;
const accreditation = data.accreditation;

const visible = { opacity: 1, y: 0, transition: { duration: 1 } };

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible,
  transition: { duration: 1 }
};

const HomePage = () => {
  const SectionComponent = (element) => {
    const { ref, inView } = useInView({
      threshold: .1,
      triggerOnce: true
    });
    const [contentReady, setContentReady] = useState(false);

    useEffect(() => {
      if (element && element.children) {
        setContentReady(true);
      }
    }, [element]);

    return (
      contentReady && (
        <motion.div
          ref={ref}
          variants={fromLeftVariants}
          initial="offscreen"
          animate={inView ? "onscreen" : "offscreen"}
          className="container"
        >
          {element.children}
        </motion.div>
      )
    );
  };

  const [openedUs, setOpenedUs] = useState({});

  const toggleUs = (usIndex) => {
    const key = `${usIndex}`;
    setOpenedUs(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };


  const location = useLocation();

  return (
    <>
    <SEO title={"Home"} description={"Your trusted maintenance partner, ensuring your portfolio is always compliant and ready."} url={location.pathname}/>
    <div className="body-inner">
      <section className="landing">
        <div className='video-background' />
        <VideoPlayer introVideo={introVideo} />
        <motion.div
          className="text-start container"
          initial="hidden"
          animate="visible"
          exit={{ opacity: 0, transition: { duration: 1 } }}
          variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
        >
          <motion.div
            className="home-main-text text-medium"
            variants={{
              hidden: { opacity: 0, y: -20 },
              visible
            }}
          >
            <h1 className="omit home-title text-dark">S3 MAINTENANCE SERVICES</h1>
            <h6 className="text-small text-dark">YOUR TRUSTED MAINTENANCE PARTNER</h6>
          </motion.div>
          <motion.p className="home-para m-b-40 lead text-dark" variants={itemVariants}>
            Explore our website to discover more about our comprehensive services, experienced team,
            and the value we can bring to your properties. Whether it's residential or commercial, S3 is here
            to keep your investments in top condition, so you can focus on what matters most to you.
          </motion.p>
          <NavLink to="/about"><button style={{ width: "10rem" }} className="btn-home-quote btn btn-dark"> Find Out More</button></NavLink>
        </motion.div>
      </section>

      <div className="background-white jumbotron jumbotron-fullwidth m-b-0">
          <div className="container">
            <h3>Take control of your property's health today.</h3>
            <p style={{ fontSize: "1.15rem" }}>At S3, we're dedicated to maintaining your properties, whether you're a <span className='colored-text'> homeowner, property landlord, property manager, or business owner.</span> With a strong presence in the UK national market, we have earned a reputation for excellence in maintenance services.</p>
            <NavLink linkClass="btn btn-outline btn-dark" to='/services/quotes'><span>Get Started Now!</span></NavLink>
          </div>
      </div>

      <section className='background-light'>
          <div className="container">
            <div className="row">
              <div style={{ margin: "none" }} className="heading-text heading-section m-b-20">
                <h2><span>WHY CHOOSE US</span></h2>
              </div>
              <div className="col-lg-6 m-b-50">
                <div className="accordion toggle fancy clean accordion-transparent">
                  {section.map((us, usIndex) => {
                    const isOpen = openedUs[`${usIndex}`] || false;
                    return (
                      <div className="ac-item" key={usIndex}>
                        <h5
                          className="ac-title"
                          onClick={() => toggleUs(usIndex)}
                        >
                          {us.title}
                        </h5>
                        <Collapse isOpened={isOpen}>
                          <div className="ac-content">{us.content}</div>
                        </Collapse>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="col-lg-6">
                <p style={{ fontSize: "1.26em" }}>At S3 Maintenance, our legacy of excellence guarantees deployment of only trusted, skilled contractors. Our dedication to reliability means we go above and beyond expectations. Our certified team adheres to the highest standards, setting us apart.
                  <br />
                  <br />
                  In emergencies, we rapidly dispatch a specialist to deliver a swift, enduring fix. Count on us for service focused on your satisfaction, offering you total peace of mind.
                </p>
              </div>
            </div>
            <div className="row m-t-50">
              <div style={{ margin: "none" }} className="heading-text heading-section m-b-20">
                <h4><span>Our clients</span></h4>
              </div>
              <ul className={"grid grid-4-columns border-bottom border-top"}>
                {clients.map((item, index) => (
                  <li key={index}>
                    <a aria-label={item.label} href={item.link} target="blank">
                      <img src={item.img} alt={item.label} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="row m-t-50">
              <div style={{ margin: "none" }} className="heading-text heading-section m-b-20">
                <h4><span>We are accredited with:</span></h4>
              </div>
              <ul className={"grid grid-3-columns border-bottom border-top"}>
                {accreditation.map((item, index) => (
                  <li key={index}>
                    <a aria-label={item.label} href={item.link} target="blank">
                      <img src={item.img} alt={item.label} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div style={{ margin: "none" }} className="heading-text heading-section m-b-20 m-t-20">
              <h4><span>Testimonials</span></h4>
            </div>

            <Testimonials />
          </div>
      </section>

      <section id="section2" className="p-t-40 no-padding background-white">
        <SectionComponent>
          <div className="container">
            <div className="row m-b-50 m-t-50">
              <Counter div={3} blackWhite={"b"} />
            </div>
          </div>
        </SectionComponent>
        <Photos data={data.projects} />
      </section>

      <section className='background-light'>
        <div className="container">
          <SectionComponent>
            <div className="heading-text heading-section m-b-80">
              <h2>WHAT WE DO</h2>
            </div>
          </SectionComponent>
          <SectionComponent>
            <WhatWeDo />
          </SectionComponent>
        </div>
      </section>

      <section className="background-white p-t-0 p-b-0">
            <img src="./images/1.jpg" alt="Our mission" className="image-about" />
      </section>
      
      <section style={{ display: "flex", flexDirection: "column" }} className="background-white section-fullwidth">
        <SectionComponent>
          <div className="container">
            <div className="heading-text heading-section m-b-30">
              <h2>OUR MISSION</h2>
              <span className="lead">Our commitment to quality, efficiency, and customer satisfaction sets us apart in the industry.
                With a team of skilled professionals and a wide range of services, we are your one-stop solution
                for all your maintenance needs.</span>
            </div>
          </div>
        </SectionComponent>
        <Values data={textBlocks} container={""} />
      </section>

      {/* <section className='background-light'>
        <SectionComponent>
          <div className="container">
            <div className="heading-text heading-section m-b-30">
              <h2>OUR TEAM</h2>
              <span className="lead">Beautiful nature, and rare feathers!. amam ipsum dolor sit amet.</span>
            </div>
            <Team />
          </div>
        </SectionComponent>
      </section> */}
    </div>
    </>
  )
}

export default HomePage;