import React, { useState } from 'react';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom"
import GoBack from '../Components/goback';

const AddJob = () => {
    const axiosPrivate = useAxiosPrivate();    
    
    const formTemplate = {
        name: 'S3 Maintenance',
        about: 'At S3, we understand the significance of maintaining the spaces where you live and work. We are your dedicated maintenance experts, proud to serve the entire UK national market. With a passion for excellence and a commitment to superior service, we are here to ensure that your properties and facilities always function at their best.',
        position: {
          title: '',
          overview: '',
          description: ''
        },
        candidate_profile: {
            description: ''
        },
        qualifications_and_experience: {
            description: ''
        },
        what_we_offer: {
            description: 'We offer a competitive salary with opportunities for advancement, alongside a generous vacation policy that includes holidays and personal days for a well-rounded work-life balance. Health, dental, and vision insurance plans are available to ensure your wellbeing. Additionally, we are committed to your continuous growth through education and professional development opportunities. Our professional yet friendly office environment is conveniently located, with great transport links, making your commute easier.'
        },
        application_instructions: {
            submission_details: 'Attach your CV and cover letter and send to the email address provided, or use the form below to submit your application. We look forward to hearing from you!'
        }
      };

      
  const [formData, setFormData] = useState(formTemplate);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [statusMessage, setStatusMessage] = useState(''); // Optional: Add a status message to display after successful form submission 
  const [color, setColor] = useState('text-primary');
  const navigate = useNavigate();

  const goBack = () => navigate(-1);
  const validate = (values) => {
    const errors = {};
    
    // Name validation
    if (!values.name || values.name.length < 4) {
    errors.name = {description: 'Name must be at least 4 characters long'};
    }
    
    // About validation
    if (!values.about || values.about.length < 10) {
    errors.about = {description: 'About section must be at least 10 characters long'};
    }
    
    // Position Title validation
    if (!values.position.title || values.position.title.length < 3) {
    errors.positionTitle = {description:  'Position title must be at least 3 characters long' };
    }
    
    // Position Overview validation
    if (!values.position.overview || values.position.overview.length < 10) {
    errors.positionOverview = {description:  'Position overview must be at least 10 characters long' };
    }
    
    // Position Description validation
    if (!values.position.description || values.position.description.length < 20) {
    errors.positionDescription = {description:  'Position description must be at least 20 characters long' };
    }
    
    // Candidate Profile Description validation
    if (!values.candidate_profile.description || values.candidate_profile.description.length < 20) {
    errors.candidate_profile = { description: 'Candidate profile description must be at least 20 characters long' };
    }
    
    // Qualifications and Experience Description validation
    if (!values.qualifications_and_experience.description || values.qualifications_and_experience.description.length < 20) {
    errors.qualifications_and_experience = { description: 'Qualifications and experience description must be at least 20 characters long' };
    }
    
    // What We Offer Description validation
    if (!values.what_we_offer.description || values.what_we_offer.description.length < 20) {
    errors.what_we_offer = { description: 'What we offer description must be at least 20 characters long' };
    }
    
    // Application Instructions Submission Details validation
    if (!values.application_instructions.submission_details || values.application_instructions.submission_details.length < 20) {
    errors.application_instructions = {description:  'Application instructions submission details must be at least 20 characters long' };
    }
    
    return errors;
};

const handleChange = (event) => {
    const { name, value } = event.target;
    const keys = name.split('.');
    if (keys.length > 1) {
        setFormData(prevData => ({
        ...prevData,
        [keys[0]]: {
        ...prevData[keys[0]],
        [keys[1]]: value
        }
        }));
    } else {
    setFormData({
        ...formData,
        [name]: value
        });
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formData);
    setFormErrors(errors);  
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      console.log(formData);
      try {
        // Make your POST request using axiosPrivate or your preferred method
        const response = await axiosPrivate.post('/careers', formData); 
        if (response.status === 201) {
            console.log(response)
          setStatusMessage('Job added successfully! - ' + response.data.position.title);
          setColor('text-success');
          toast.success('Job Added Successfully');
          // Handle successful creation (reset form, display message, etc.)
        } else {
          console.error('Job creation failed:', response);
          // Handle error
          toast.error('Error adding job, refresh and try again.');
          setStatusMessage('Job creation failed');
          setColor('text-danger');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsSubmitting(false);
        // setFormData(formTemplate);
      }
    }
  };

  return (
    <>
    <section className="p-0 container-login">
        <div className="card-header">
        <div className="heading-text heading-gradient"><h2 style={{fontSize:"2.5rem"}}><span>Job posting form</span></h2></div>
        <p className="">Add job details</p>
        </div>
        <div className="card-body">
            <form className="form-validate" onSubmit={handleSubmit}>
                    <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                        <label htmlFor="name">Company Name:</label>
                        <input type="text" id="name" name="name" className="form-control" value={formData.name} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                        <label htmlFor="about">About the Company:</label>
                        <textarea id="about" name="about" className="form-control" value={formData.about} onChange={handleChange} required style={{ minHeight: '160px' }}>
                        </textarea>
                        </div>
                    </div>

                {/* Position Subdocument */}
                <div className="col-md-12">
                    <h4>Position Details</h4>
                    <div className="form-group">
                    <label htmlFor="position.title">Job Title:</label>
                    <input type="text" id="position.title" name="position.title" className="form-control" value={formData.position.title} onChange={handleChange} required />
                    </div>
                    
                        {/* Job Overview Field */}
                        <div className="form-group">
                        <label htmlFor="position.overview">Job Overview:</label>
                        <textarea id="position.overview" name="position.overview" className="form-control" value={formData.position.overview} onChange={handleChange} required style={{ minHeight: '100px' }}/>
                        </div>

                        {/* Job Description Field */}
                        <div className="form-group">
                        <label htmlFor="position.description">Job Description:</label>
                        <textarea id="position.description" name="position.description" className="form-control" value={formData.position.description} onChange={handleChange} required style={{ minHeight: '100px' }}/>
                        </div>

                </div>

                {/* Candidate Profile */}
                <div className="col-md-12">
                    <h4>Ideal Candidate Profile</h4>
                    <div className="form-group">
                    <label htmlFor="candidate_profile.description">Description:</label>
                    <textarea  id="candidate_profile.description" name="candidate_profile.description" className="form-control" value={formData.candidate_profile.description} onChange={handleChange} required style={{ minHeight: '160px' }} />
                    </div>
                </div>

                <div className="col-md-12">
                    <h4>Qualifications and Experience</h4>
                    <div className="form-group">
                    <label htmlFor="qualifications_and_experience.description">Description:</label>
                    <textarea id="qualifications_and_experience.description" name="qualifications_and_experience.description"  className="form-control"  value={formData.qualifications_and_experience.description}  onChange={handleChange} required style={{ minHeight: '160px' }} />
                    </div>
                </div>

                <div className="col-md-12">
                    <h4>What We Offer</h4>
                    <div className="form-group">
                    <label htmlFor="what_we_offer.description">Description:</label>
                    <textarea id="what_we_offer.description" name="what_we_offer.description" className="form-control" value={formData.what_we_offer.description} onChange={handleChange} required style={{ minHeight: '160px' }} />
                    </div>
                </div>

                {/* Application Instructions */}
                <div className="col-md-12">
                    <h4>Application Instructions</h4>
                    <div className="form-group">
                    <label htmlFor="application_instructions.submission_details">Submission Details:</label>
                    <textarea id="application_instructions.submission_details" name="application_instructions.submission_details" className="form-control" value={formData.application_instructions.submission_details} onChange={handleChange} required style={{ minHeight: '160px' }} />
                    </div>
                </div>

                    <div className="col-md-12">
                    <div className="form-group right add-job-container">
                        <p className={color+ " add-job-status"}>{statusMessage}</p>
                        <button type="submit" className="btn btn-primary right" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Add Job'}
                        </button>
                        {Object.keys(formErrors).length > 0 && ( // Display errors if any
                            <div className="error-messages">
                                <p>Please fix the following errors:</p> 
                                <ul>
                                    {Object.values(formErrors).map((error, index) => (
                                        <li key={index}>{error.description}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
        <div className="m-t-10 form-group center">
            <GoBack />
        </div>
    </>
  );
};

export default AddJob;