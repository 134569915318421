import React from 'react';

const keywords = {
  services: ['service', 'services', 'what you offer', 'offerings'],
  quotes: ['quote', 'quotes', 'pricing', 'estimate'],
  projects: ['project', 'projects', 'portfolio', 'work done'],
  clients: ['client', 'clients', 'customers', 'who you work with'],
  about: ['about', 'company', 'mission', 'team'],
  blog: ['blog', 'articles', 'news', 'updates'],
  contact: ['contact', 'get in touch', 'reach out', 'help'],
  pricing: ['pricing', 'cost', 'rates', 'fees'],
  sitemap: ['sitemap'],
  terms: ['terms', 'terms of service', 'terms and conditions'],
  careers: ['careers', 'jobs', 'openings'],
  complaints: ['complaints', 'feedback'],
  faqs: ['faq', 'frequently asked questions'],
  privacy: ['privacy policy', 'data privacy'],
  salutation : ['hi', 'hello']
};



const MessageParser = ({ children, actions }) => {

    const parse = (message) => {
    const lowerMessage = message.toLowerCase();
    for (const key in keywords) {
      if (keywords[key].find((keyword) => lowerMessage.includes(keyword))) {
        const res = `handle${key.charAt(0).toUpperCase() + key.slice(1)}`;
        if (typeof actions[res] === 'function') actions[res]();
        return res
      }
    }
    return actions['handleDefault']();
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, { parse: parse, actions });
      })}
    </div>
  );
};

export default MessageParser;