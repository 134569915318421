import React, { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
import { NavLink } from "../NavLink/navLink";
import { getCookie, setCookie } from "./cookiesFunctions";
import { initializeGA, sendGA } from "../../services/GAConfig";

import "./cookies.css";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const navigate = useNavigate();
  const isBannerClosed = getCookie("cookiesAllowed");
  useEffect(() => {
    if (isBannerClosed) {
      initializeGA();
      sendGA();
    } else {
      setShowBanner(true);
    }
  
  }, []);

  const [ cookiesPermited, setCookiesPermited ] = useState(false);

  useEffect(() => {
    if(getCookie("cookiesAllowed")) {
      initializeGA();
      sendGA();
    }
  },[cookiesPermited])
  

  const handleCloseBanner = (e) => {
    const accepted = e.currentTarget.value
    setShowBanner(false);
    if(accepted) {
      setCookie("cookiesAllowed", true, 365);
      setCookiesPermited(true)
  } 
  };

  return (
    showBanner && (
      <div
        id="cookieNotify"
        className="modal-strip cookie-notify background-grey-dark modal-active"
        data-expire="1"
        data-cookie-name="cookiebar2021_1"
        data-cookie-enabled="true"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 text-sm-center sm-center sm-m-b-10 m-t-5 cookies-div">
              This website uses cookies to ensure you get the best experience on
              our website.{" "}
              <button onClick={function Clicked(){navigate("/privacy-policy")}} className="text-light cookie-banner-button">
                <span style={{paddingLeft:"10px"}}>
                  {" "}
                   <NavLink to='/privacy-policy' >
                    Privacy Policy
                    </NavLink> 
                    <i className="fa fa-info-circle"></i>
                </span>
              </button>
            </div>
            <div className="col-lg-4 text-end sm-text-center sm-center cookies-buttons">
              <button
                type="button"
                className="btn btn-roundeded btn-light btn-outline btn-sm m-r-10 modal-close"
                onClick={handleCloseBanner}
                value={false}
              >
                Decline
              </button>
              <button
                type="button"
                className="btn btn-roundeded btn-light btn-sm modal-confirm"
                onClick={handleCloseBanner}
                value={true}
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};


export default CookieBanner;
