import React from 'react';

const SocialMedia = (data) => {
    const iconColor = data.class;
    let style = "col-md-6 d-none d-sm-block";
    if (!data.type){
        style ="col-md-4 d-none d-sm-block"
    } 
    return (
        <div className={style}>
            <div className="social-icons social-icons-colored-hover">
                <ul>
                    <li className="social-facebook"><a aria-label="Facebook" className={iconColor} href="https://www.facebook.com/profile.php?id=61557075662415"><i className="fab fa-facebook-f"/></a></li>
                    <li className="social-twitter "><a aria-label="Twitter" className={iconColor} href="https://twitter.com/S3Maintenanceuk"><i className="fab fa-twitter"/></a></li>
                    <li className="social-google "><a aria-label="Google" className={iconColor} href="https://maps.app.goo.gl/R5FcXeFfg1Qeh1af9"><i className="fab fa-google-plus-g"/></a></li>
                    <li className="social-instagram"><a aria-label="Instagram" className={iconColor} href="https://www.instagram.com/s3maintenance/"><i className="fab fa-instagram"/></a></li>
                    <li className="social-linkedin "><a aria-label="Linkedin" className={iconColor} href="https://www.linkedin.com/company/s3-maintenance-services/?viewAsMember=true"><i className="fab fa-linkedin"/></a></li>
                    <li className="social-behance"><a aria-label="Trustpilot" className={iconColor} href="https://uk.trustpilot.com/review/s3maintenance.com"><i className="fa fa-star"/></a></li>
                </ul>
            </div>
        </div>
  );
};

export default SocialMedia;
