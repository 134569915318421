import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SortableTable from '../Components/sort.jsx';

const Applicants = () => {
    const [applicants, setApplicants] = useState([]);
    const [filteredApplicants, setFilteredApplicants] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const axios = useAxiosPrivate();

    const sortByNameAscending = (a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    };

    const sortByPositionAscending = (a, b) => {
        const positionA = a.currentPosition.toLowerCase();
        const positionB = b.currentPosition.toLowerCase();
        if (positionA < positionB) return -1;
        if (positionA > positionB) return 1;
        return 0;
    };

    const sortByDateDescending = (a, b) => {
        return new Date(b.dateApplied) - new Date(a.dateApplied);
    };

    const sortByDateAscending = (a, b) => {
        return new Date(a.dateApplied) - new Date(b.dateApplied);
    };

    const sortApplicants = (field, ascending) => {
        const sortedData = [...filteredApplicants];
        switch (field) {
            case 'name':
                sortedData.sort(sortByNameAscending);
                break;
            case 'currentPosition':
                sortedData.sort(sortByPositionAscending);
                break;
            case 'createdAt':
                sortedData.sort(ascending ? sortByDateAscending : sortByDateDescending);
                break;
            default:
                break;
        }

        if (!ascending) sortedData.reverse();

        setFilteredApplicants(sortedData);
    };

    const TABLE_CONFIG = [
        { key: 'name', name: 'Applicant Name' },
        { key: 'email', name: 'Email' },
        { key: 'currentPosition', name: 'Position' },
        { key: 'createdAt', name: 'Date Applied' },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/application');
                setApplicants(response.data);
            } catch (error) {
                console.error('Error fetching careers data:', error);
            }
        };
        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setSearchTerm(searchValue);

        const newFilteredApplicants = applicants.filter((applicant) => {
            return (
                applicant.name.toLowerCase().includes(searchValue) ||
                applicant.email.toLowerCase().includes(searchValue) ||
                applicant.currentPosition.toLowerCase().includes(searchValue) ||
                applicant.createdAt.toLowerCase().includes(searchValue)
            );
        });

        setFilteredApplicants(newFilteredApplicants);
    };

    useEffect(() => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const newFilteredApplicants = applicants.filter((applicant) => {
            return (
                applicant.name.toLowerCase().includes(lowerCaseSearchTerm) ||
                applicant.email.toLowerCase().includes(lowerCaseSearchTerm) ||
                applicant.currentPosition.toLowerCase().includes(lowerCaseSearchTerm) ||
                applicant.createdAt.toLowerCase().includes(lowerCaseSearchTerm)
            );
        });

        setFilteredApplicants(newFilteredApplicants);
    }, [searchTerm, applicants]);

    return (
        <section style={{ padding: "4rem", paddingTop: "1rem" }} className="container-login">
            <div className="heading-text heading-gradient">
                <h2 style={{ fontSize: "2.5rem" }}><span>Applications</span></h2>
            </div>
            <div className="form-group">
                <input
                    className='form-control notification-message"'
                    type="text"
                    placeholder="Search applicants..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            {filteredApplicants.length ? (
                <SortableTable
                    data={filteredApplicants}
                    config={TABLE_CONFIG}
                    sortTable={sortApplicants}
                />
            ) : (
                <p style={{ paddingTop: "5rem" }}>No applicants to display</p>
            )}
        </section>
    );
};

export default Applicants;
