import React, { useState } from 'react';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import toast from 'react-hot-toast';
import GoBack from '../Components/goback';

const AddBlog = () => {
    const axiosPrivate = useAxiosPrivate();     

    const formTemplate = {
        title: '', // Added title
        description: 'Enter a descriptive introduction to your blog post here...', 
        images: null, // Add field for image if needed (could be a single image or an array of image URLs) 
        type: 'News',  // Add field for blog type  
        body: 'Start writing your blog post here...', // Main body field
        links: [] // Add an optional links field if you want to include external links, 
    };

    const [formData, setFormData] = useState(formTemplate);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [statusMessage, setStatusMessage] = useState('');
    const [color, setColor] = useState('text-primary');


    const validate = (values) => {
        const errors = {};

        // Title validation
        if (!values.title || values.title.length < 4) {
            errors.title = { description: 'Title must be at least 4 characters long' };
        }

        // Description validation
        if (!values.description || values.description.length < 10) {
            errors.description = { description: 'Description must be at least 10 characters long' };
        }

        // Blog Body validation
        if (!values.body || values.body.length < 50) {
            errors.body = { description: 'Blog body must be at least 50 characters long' };
        }

        return errors;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleAddLink = () => {
        setFormData({ ...formData, links: [...formData.links, ''] });
    };

    const handleLinkChange = (index, newLink) => {
        const updatedLinks = [...formData.links];
        updatedLinks[index] = newLink;
        setFormData({ ...formData, links: updatedLinks });
    };

    const handleImageChange = (event) => {
        setFormData({
            ...formData,
            image: event.target.files[0] // Store the selected image file
        });
    };

    const handleRemoveLink = (index) => {
        const updatedLinks = [...formData.links];
        updatedLinks.splice(index, 1); // Remove the link at the specified index
        setFormData({ ...formData, links: updatedLinks });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validate(formData);
        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            const uploadData = new FormData(); // Create FormData for image upload
            uploadData.append('image', formData.image);
            // Append other form data as needed
            uploadData.append('title', formData.title);
            uploadData.append('description', formData.description);
            uploadData.append('type', formData.type);
            uploadData.append('body', formData.body);
            uploadData.append('links', JSON.stringify(formData.links)); 
            try {
                const response = await axiosPrivate.post('/blog', uploadData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                    });
                if (response.status === 201) { 
                    setStatusMessage('Blog added successfully!');
                    setColor('text-success');
                    toast.success('Blog Added Successfully');
                } else {
                    console.error('Blog creation failed:', response);
                    setStatusMessage('Blog creation failed');
                    setColor('text-danger');
                    toast.error('Error adding blog, refresh and try again.');
                }
            } catch (err) {
                console.error(err);
                toast.error('Error adding blog', err);
            } finally {
                setIsSubmitting(false); 
            }
        }
    };

    const star = <span className='required-star'>*</span>;

    return (
        <>
        <section className="p-0 container-login">
            <div className="card-header">
                <div className="heading-text heading-gradient"><h2 style={{ fontSize: "2.5rem" }}><span>Blog posting form</span></h2></div>
                <p className="">Add blog details {star}</p>
            </div>
            <div className="card-body">
                <form className="form-validate" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="title">Title:{star}</label>
                                <input type="text" id="title" name="title" className="form-control" value={formData.title} onChange={handleChange} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="description">Description:{star}</label>
                                <textarea id="description" name="description" className="form-control" value={formData.description} onChange={handleChange} required style={{ minHeight: '100px' }}>
                                </textarea>
                            </div>

                            {/* Image Upload Form */}
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="image">Select Image:{star}</label>
                                    <input type="file" id="image" name="image" accept="image/jpeg, image/png, image/gif" onChange={(event) => handleImageChange(event)} required/>
                                </div>
                            </div>

                            {/* Blog Type Field  */}
                            <div className="form-group">
                                <label htmlFor="type">Subject{star}</label>
                                <select id="type" name="type" className="form-control" value={formData.type || 'News'} onChange={handleChange} required>
                                    <option value="Sustainability" selected>Sustainability</option>
                                    <option value="Regulations">Regulations</option>
                                    <option value="Health & Safety">Health & Safety</option>
                                    <option value="News">News</option>
                                    <option value="Removals">Removals</option>
                                    <option value="Equipment & Tools">Equipment & Tools</option>
                                    <option value="Cost-Saving Strategies">Cost-Saving Strategies</option>
                                    <option value="Industry Insights">Industry Insights</option>
                                    <option value="Customer Focused">Customer Focused</option>
                                </select> 
                            </div>

                            <div className="form-group">
                                <label htmlFor="body">Blog Content:{star}</label>
                                <textarea id="body" name="body" className="form-control" value={formData.body} onChange={handleChange} required style={{ minHeight: '200px' }}/>
                            </div>

                            {/* Optional: Links field */}
                            <div className="form-group">
                                <label htmlFor="links">External Links: <span className='text-muted'>optional</span></label>
                                {formData.links.map((link, index) => (
                                    <div key={index} className="input-group mb-2">
                                        <input
                                            type="text"
                                            name={`link_${index}`} 
                                            className="form-control"
                                            value={link}
                                            onChange={(event) => handleLinkChange(index, event.target.value)}
                                            placeholder="Enter a link"
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-danger btn-sm btn-erase-link" type="button" onClick={() => handleRemoveLink(index)}>
                                                <i className="fas fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                                <button type="button" className="btn btn-secondary" onClick={handleAddLink}>
                                    Add Link
                                </button>
                            </div>

                            <div className="col-md-12">
                                <div className="add-job-container">
                                    <button type="submit" className="btn btn-primary add-blog-right" disabled={isSubmitting}>
                                        {isSubmitting ? 'Submitting...' : 'Add Blog'}
                                    </button>
                                </div>
                                <p className={color + " add-job-status add-blog-right"}>{statusMessage}</p>
                                    {Object.keys(formErrors).length > 0 && (
                                        <div className="right error-messages">
                                            <p className='text-red' >Please fix the following errors:</p>
                                            <ul>
                                                {Object.values(formErrors).map((error, index) => (
                                                    <li className='text-red' key={index}>{error.description}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                
                            </div>
                        </div>
                        </div>
                    </form>
                </div>
            </section>
            <div className="m-t-10 form-group">
            <GoBack />
            </div>
            </>
    );
};

export default AddBlog;
