import React, {useState} from 'react';
import {PhotoAlbum} from "react-photo-album";
import Modal from 'react-bootstrap/Modal';

import "./photoalbum.css";

const RenderPhoto = ({ layout, layoutOptions, imageProps }) => {
    // let subtitle;
    const { alt, style, title,  ...restImageProps } = imageProps;
    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
        document.body.style.maxHeight = 'none'
      }
    
    //   function afterOpenModal() {
    //     // references are now sync'd and can be accessed.
    //     subtitle.style.color = '#f00';
    //   }
      function closeModal() {
        setIsOpen(false);
      }
return (
    <div className='photos-div'>
        <img alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} />
        <div className='photo-album-title' >
            {alt}
            {/* <button className="btn-modal btn btn-primary" onClick={openModal}>About</button> */}
        </div>
            <Modal
            // className="modal-dialog modal-lg"
            show={modalIsOpen}
            onHide={closeModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    {alt}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{title}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" type="button" onClick={closeModal}>Close</button>
                </Modal.Footer>
        </Modal>
    </div>
    );
};

const Photos = (data) => {

    const array = data.data;
    let photos = [];
    for (let i = 0; i < array.length; i++) {
        const element = array[i];
        photos.push({
            src: element.url,
            width: 1600,
            height: 1200,
            title: element.description,
            alt: element.name,
        });
    }
  return (
    <div id="portfolio" className="portfolio-4-columns">
        <PhotoAlbum 
        layout="columns" 
        columns={(containerWidth) => {
            if (containerWidth < 400) return 1;
            if (containerWidth < 800) return 2;
            return 4;
            }} 
        padding={0} spacing={10} photos={photos} 
        renderPhoto={RenderPhoto}
        />          
    </div>
  );
};

export default Photos;
