import React from 'react';
import services from './data.jsx';

const WhatWeDo = () => {
  return (
        <div className="row">
        {services.map((service, index) => (
            <div key={index} className="col-lg-6">
                <div className="icon-box medium fancy">
                    <div className="icon"> <i className="fa">{service.icon}</i></div>
                    <h4>{service.name}</h4>
                    <p>{service.description}</p>
                </div>
            </div>
            ))}
        </div> 
  );
};

export default WhatWeDo;
