function validFileSize(file, maxSize) {
	const maxSizeInBytes = maxSize * 1024 * 1024;

	if (file.size > maxSizeInBytes) {
		return false;
	}

	return true;
}

export { validFileSize };
