import React from 'react';
import { Spinner } from 'react-bootstrap';

const SpinnerLoading = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50%',
            width: '50%',
            margin: 'auto',
            position: 'relative',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: '9999',
            scale: '2'
        }}>
            <Spinner variant='primary' animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
};

export default SpinnerLoading;