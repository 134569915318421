import axios from "./api";
import useAuth from "./useAuth";
import toast from 'react-hot-toast';

const useLogout = () => {
    const { setAuth } = useAuth();

    const logout = async () => {
        setAuth({});
        try {
            await axios('/logout', {
                withCredentials: true
            });
            toast.success('Logged out successfully');
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout