import { Link, useNavigate } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
            <section style={{overflow:"hidden"}} className="container-login h-100 w-100 px-3 px-sm-5 px-xl-7">
                    <h1 style={{margin:"auto", justifyContent:"center", alignContent:"center", display:"flex"}}>Unauthorized</h1>
                    <p style={{margin:"auto", justifyContent:"center", alignContent:"center", display:"flex"}}>You do not have access to the requested page.</p>
                    <button onClick={goBack}>Go back</button>
                    <p style={{marginTop:"1rem", justifyContent:"center", alignContent:"center", display:"flex"}}>
                        Or  <Link style={{paddingLeft:".5rem", paddingRight:".5rem"}} to="/contact"> contact</Link> an admin for clearance.
                    </p>

            </section>
        )
}

export default Unauthorized