

import axios from '../hooks/api';

const sendEmail = async (type, formData) => {
 
  try {
    await fetch(`${process.env.REACT_APP_URL}send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({type, formData}),
    });

    return true;
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

const fileUpload = async (type, formData, file) => {
  try {
    const formDataObject = new FormData();
    formDataObject.append('type', type);
    formDataObject.append('formData', JSON.stringify(formData));
    formDataObject.append('file', file);
    const response = await axios.post(`${process.env.REACT_APP_URL}send-email/upload`, formDataObject, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};

const NewsLetterSub = async (formData) => {
  try {
    const response = await axios.post(`fe/newsletter`, formData,{
      headers: {
        'Content-Type': 'application/json',
      },
      
    });

    return response.data;
  } catch (error) {
    console.error('Error subscribing:', error);
    throw error;
  }
}

export { sendEmail, fileUpload, NewsLetterSub };