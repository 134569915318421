import { useNavigate, Link } from "react-router-dom";


export const NavLink = ({ linkClass,  to, children }) => {
  const navifgate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navifgate(to);
    window.scrollTo(0, 0);
  };

  return (
    <Link className={linkClass} to={to} onClick={handleClick}>
      {children}
    </Link>
  );
};