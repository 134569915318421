import React, {useState, useEffect} from "react";
import { NavLink } from "../../../../components/NavLink/navLink";
import './linkComponent.css'

export const LinkComponent = (props) => {

  const {to, text, delay = 0} = {...props}

  const [displayEffect, setDisplayEffect] = useState(false);

  useEffect(()=>{
  },[displayEffect])

  
  setTimeout(function() {
    setDisplayEffect(true)
  }, (500 + (500 * delay)));
  return (
    <div className={`chat-bot-link ${displayEffect ? 'chat-bot-link-active' : ''}`}>
      <NavLink to={to}>{text}</NavLink>

    </div>
  );

}