import React, { useEffect, useState } from "react";
import { NavLink } from "../NavLink/navLink";
// import logo from "../../images/logos/logo_white.png";
import { useNavigate } from 'react-router-dom';
import logo from "../../images/logos/logo.png";
import { FaUser} from "react-icons/fa";

import "./header.css";

const Header = () => {
  const [openLinks, setOpenLinks] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isPageTop, setIsPageTop] = useState(true);
  const [scrollHis, setScrollHis] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
	window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
	}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[openLinks, scrollHis])

  const handleScroll = () => {
    closeMenu();
    const currentScrollY = window.scrollY;
    
    if (currentScrollY < scrollHis && currentScrollY > 80) {
      setIsScrollingUp(true);
    } else {
      setIsScrollingUp(false);
    }
    if(currentScrollY === 0)  {
      setIsPageTop(true);
    }else setIsPageTop(false);

    setScrollHis(currentScrollY);
  };

  const pages = [
    {
      title: "Home",
      endpoint: "/",
    },
    {
      title: "Services",
      endpoint: "/services",
    },
    {
      title: "Projects",
      endpoint: "/projects",
    },
    {
      title: "Clients",
      endpoint: "/clients",
    },
    {
      title: "About",
      endpoint: "/about",
    },
    {
      title: "News",
      endpoint: "/blog",
      // isDisabled: true
    },
    {
      title: "Contact",
      endpoint: "/contact",
    },
  ];

  const toggleLinks = () => {
	setOpenLinks(!openLinks)
  }

  
  const displayLinks = (linkClass) => {
    return pages.map((elem, idx) => (
      <li className="header-text" key={`header-link-${idx}`}>
        {elem.isDisabled ? (
          <span className={linkClass} style={{ color: "gray", cursor: "not-allowed" }}>
            {elem.title}
          </span>
        ) : (
          <NavLink linkClass={linkClass} to={elem.endpoint}>
            {elem.title}
          </NavLink>
        )}
      </li>
    ));
  };

  const closeMenu = () => {
	if(openLinks)setOpenLinks(false);
  }

  return (
    <header className="background-header" data-fullwidth="true" style={{height: "80px"}}>
      <div className="login-nav" >
        <NavLink to="/login">
          <FaUser className="user-icon"/>
        </NavLink>
      </div>
      <div className={`${isScrollingUp ? 'scrolled' : isPageTop ? "" : window.scrollY < 100 ? "": "scrolleDown"}`} style={window.scrollY > 200 ? {transition: "all .3s ease"} : {}}>
        <div className={`container `}>
        <div className="header-inner">
          <div className={`header-navbar-menu ${openLinks ? "open" : ""}`} onClick={toggleLinks}>
              <nav>
                <ul className="header-navbar-ul header-menu-links">
                  {displayLinks("header-menu-link")}
                </ul>
                <button style={{margin:"auto", height:"80%"}} 
                        onClick={() => navigate('/gas-safety-certificate')}
                        className="btn btn-primary btn-sm">Gas safety Certificate</button>
              </nav>
            </div>
            <div className="header-container">
              <div>
                <NavLink linkClass="logo-link" to="/">
                  <img className="header-logo" src={logo} alt="logo"></img>
                </NavLink>
              </div>
          <div className={`header-navbar-btn-box ${openLinks ? "toggle-action" : ""}`} onClick={toggleLinks} >
            <span className="header-navbar-lines"></span>
          </div>
                <div className={`header-navbar-list`}>
                  <nav>
                    <ul className="header-navbar-ul">
                      {displayLinks("header-navbar-link")}
                    </ul>
                  </nav>
                </div>
            </div>
          </div>
        </div>
      </div>
      
    </header>
  );
};

export default Header;
