import React from 'react';
import SocialMedia from '../socialmedia';
import { useNavigate } from 'react-router-dom';
import './topbar.css'


const Topbar = () => {
    const navigate = useNavigate();
  return (
        <div id="topbar" className="d-none d-xl-block d-lg-block background-gradient-light">
            <div className="container">
                <div className="row">
                    <div style={{display:"flex"}} className="col-md-8">
                        <ul className="top-menu">
                            <li><a aria-label="phone" className='text-grey topbar-link' href="tel:+1234567890">Phone: +44 20 7794 8159</a></li>
                            <li><a aria-label="email" className='text-grey topbar-link' href="mailto:info@s3maintenance.co.uk">Email: info@s3maintenance.co.uk</a></li>
                        </ul>
                        <button style={{margin:"auto", height:"80%"}} 
                        onClick={() => navigate('/gas-safety-certificate')}
                        className="btn btn-primary btn-sm">Gas safety Certificate</button>
                    </div>
                    <SocialMedia type={false} />
                </div>
            </div>
        </div>    
  );
};

export default Topbar;
