
import React, {useState} from "react";
import { BiCommentError } from "react-icons/bi";
// import { Fade, Flip  } from "react-awesome-reveal";

import config from '../../services/ChatBot/config.js';
import MessageParser from '../../services/ChatBot/MessageParser.jsx';
import ActionProvider from '../../services/ChatBot/ActionProvider.jsx';

import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'

import "./chatbot.css";

const Bot = () => {
  const [showBot, toggleBot] = useState(false);
  return (
    <>
   {showBot && (
          <div className="app-chatbot-container">
            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
          </div>
      )}
        <button
          className="app-chatbot-button"
          onClick={() => toggleBot((prev) => !prev)}
        >
          <div>Help</div>
          <BiCommentError/>
        </button>
        </>
  );
};

export default Bot;