import { ReactComponent as Scroll } from "../../images/scroll.svg";
import React from 'react';

import './scroll.css';

function ScrollIcon(){

    const handleChildClick = () => {
        // You could stop the native event propagation if you need to:
        window.scrollTo(0, 0)
    }
    return(
        <>
            <Scroll onClick={handleChildClick} className='scroll'/>
        </>    
    );
}
export default ScrollIcon;