import React, { useEffect } from 'react';

import arrows from '../../images/logos/arrows.svg';
import letters from '../../images/logos/letters.svg';


import "./spinner.css";

const Spinner = () => {

  const toggleBodyOverflow = (enable) => {
    document.body.style.overflow = enable ? '' : 'hidden';
    document.body.style.maxHeight = enable ? 'none' : '100vh';
  };

  useEffect(() => {
    toggleBodyOverflow(false);
    return () => {
      toggleBodyOverflow(true);
    };
  }, []);

	return (
		<div className="s3-spinner-bg">
			<div className="loading-spinner">
				<img className="s3-spinner-img-arrows rotating" src={arrows} alt="" />
				<img className="s3-spinner-img-text fixed" src={letters} alt="" />
			</div>
		</div>
	);
};

export default Spinner