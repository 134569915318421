import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ReactComponent as Letters } from '../../images/logos/letters.svg';
import { ReactComponent as Arrows } from '../../images/logos/arrows.svg';
import './animation.css';

const SuspenseAnimation = () => {
    const svg = useRef(null);

    useEffect(() => {
        const logo = svg.current;
        gsap.set(logo, { opacity: 0 });
        const tl = gsap.timeline();
        tl.delay(0.1)
            .to(logo, { opacity: 1, duration: .5 })
            .fromTo(
                logo,
                { strokeDasharray: '4400px', scale: 1.1 },
                { strokeDasharray: '0px', duration: 0.2, ease: 'power4.out' }
            )
            .to(logo, {
                rotation: 360 * 1.2,
                scale: 1.2,
                ease: 'power2.inOut',
                repeat: -1,
                yoyo: true,
                transformOrigin: 'center center',
                duration: 2,
            });
    }, []);

    return (
        <div
            style={{
                position: 'relative',
                top: 0,
                left: 0,
                zIndex: 1000,
                margin: 0,
                padding: 0,
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'var(--white)',
            }}
            className="suspense-animation"
        >
            <div className="animation-container">
                <div style={
                    {
                      height: '100%',
                      width: '100%', 
                      margin: "auto",
                    }                }
                className="logo-housing"

                >
                <Arrows
                    style={{
                        position: 'absolute',
                    }}
                    ref={svg}
                />
                <Letters
                    style={{
                        position: 'absolute',
                    }}
                />
                </div>
            </div>
        </div>
    );
};

export default SuspenseAnimation;
