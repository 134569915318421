import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Selector from "../../components/Selector/selector";
import DatePicker from "react-datepicker";
import axios from "../../hooks/api";
import "react-datepicker/dist/react-datepicker.css";
import SEO from "../../components/SEO";
import data from "../../components/data.json";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Link } from "react-router-dom";
import "./gas.css";

const Gas = () => {
	let { preselecType, preselectService } = useParams();
	const accreditation = data.gas;
	const {
		placesService,
		placePredictions,
		getPlacePredictions,
		isPlacePredictionsLoading,
	} = usePlacesService({
		apiKey: process.env.REACT_APP_GOOGLE,
	});

	const [form, setForm] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		address: {
			all: "",
			line1: "",
			city: "",
			county: "",
			zip: "",
		},
		preferredDate: null,
		preferredTime: null,
		gasCertification: null,
		electricalCertificate: null,
		patTesting: null,
		epcServices: null,
		parking: "Free Parking",
		congestionFee: null,
		paymentMethod: null,
		additionalInfo: null,
		total: 0,
		isValid: false,
	});

	const [addressObject, setAddress] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [statusMessage, setStatusMessage] = useState("");
	const [color, setColor] = useState("text-primary");
	const [showDropdown, setShowDropdown] = useState(false);

	const navigate = useNavigate();

	const extractAddressComponents = (placeDetails) => {
		setAddress(placeDetails);
		const addressLine1 =
			placeDetails.address_components.find((comp) =>
				comp.types.includes("street_number")
			)?.long_name || "";

		const route =
			placeDetails.address_components.find((comp) =>
				comp.types.includes("route")
			)?.long_name || "";

		return {
			line1: addressLine1 + " " + route,
			city:
				placeDetails.address_components.find(
					(comp) =>
						comp.types.includes("postal_town") ||
						comp.types.includes("locality")
				)?.long_name || "",
			county:
				placeDetails.address_components.find(
					(comp) =>
						comp.types.includes("administrative_area_level_2") ||
						comp.types.includes("administrative_area_level_1")
				)?.long_name || "",
			zip:
				placeDetails.address_components.find((comp) =>
					comp.types.includes("postal_code")
				)?.long_name || "",
		};
	};

	useEffect(() => {
		validateForm();
	}, [
		form.firstName,
		form.lastName,
		form.email,
		form.address,
		form.preferredDate,
		form.gasCertification,
	]);

	useEffect(() => {
		if (placePredictions.length) {
			const placeId = placePredictions[0]?.place_id;

			if (placeId) {
				placesService?.getDetails({ placeId }, (placeDetails) => {
					if (placeDetails) {
						const address = extractAddressComponents(placeDetails);
						setForm((prevForm) => ({
							...prevForm,
							address,
						}));
					}
				});
			}
		}
	}, [placePredictions, placesService]);

	const handlePredictionClick = (prediction) => {
		setForm((prevForm) => ({
			...prevForm,
			address: { line1: prediction.description },
		}));
		setShowDropdown(false);
		getPlacePredictions({ input: prediction.description });
	};

	const renderItem = (item) => (
		<button
			type="button"
			className="dropdown-item"
			onClick={() => handlePredictionClick(item)}
			key={item.place_id}
		>
			{item.description}
		</button>
	);

	const validateForm = useCallback(() => {
		const requiredFields = [
			"firstName",
			"lastName",
			"email",
			"address.line1",
			"address.zip",
			"preferredDate",
		];

		const isOtherFieldsValid = requiredFields.every((field) => {
			const keys = field.split(".");
			const value = keys.reduce((acc, key) => acc[key], form);
			return Boolean(value);
		});

		const isGasCertificationValid =
			form.gasCertification === true || form.gasCertification === false;

		const isValid = isOtherFieldsValid && isGasCertificationValid;

		setForm((prevForm) => ({ ...prevForm, isValid }));
	}, [form]);

	const handleChange = (field) => (event) => {
		const { value } = event.target;
		if (field.includes("address")) {
			const [mainKey, subKey] = field.split(".");
			setForm((prevForm) => ({
				...prevForm,
				[mainKey]: { ...prevForm[mainKey], [subKey]: value },
			}));
		} else {
			let updatedValue = value; // Initialize the updated value to the event's value
			if (
				field === "gasCertification" ||
				field === "electricalCertificate" ||
				field === "patTesting" ||
				field === "epcServices" ||
				field === "congestionFee"
			) {
				// If the field belongs to the ones which should have boolean values, convert the value
				updatedValue =
					value === "true" ? true : value === "false" ? false : value;
			}
			setForm((prevForm) => ({ ...prevForm, [field]: updatedValue }));
		}
	};

	const submitForm = async (e) => {
		e.preventDefault();
		if (!form.isValid) return;

		const formattedDate = `${form.preferredDate.getDate()}/${
			form.preferredDate.getMonth() + 1
		}/${form.preferredDate.getFullYear()}`;
		const formData = {
			...form,
			preferredDate: formattedDate,
			addressObject: addressObject,
		};
		console.log(formData);
		setIsSubmitting(true);
		try {
			const response = await axios.post("/fe/quotes/gas", formData);
			if (response.status === 201) {
				setStatusMessage("Quote submitted successfully!");
				setColor("text-success");
				navigate("/confirmation");
			} else {
				throw new Error("Failed to submit quote");
			}
		} catch (error) {
			console.error(error);
			setStatusMessage(
				"Quote submission failed - contact support@s3maintenance.co.uk"
			);
			setColor("text-danger");
		} finally {
			setIsSubmitting(false);
		}
	};

	const timeSlots = [
		{ label: "8am – 12pm", value: "8am–12pm" },
		{ label: "12pm – 4pm", value: "12pm–4pm" },
	];

	const serviceOptions = {
		gasCertificationOptions: [
			{ label: "Not Required", value: false },
			{ label: "Gas Certificate CP12", value: true },
		],
		electricalCertificateOptions: [
			{ label: "Not Required", value: false },
			{ label: "Electrical Certificate (EICR)", value: true },
		],
		patTestingOptions: [
			{ label: "Not Required", value: false },
			{ label: "PAT Testing", value: true },
		],
		epcServicesOptions: [
			{ label: "Not Required", value: false },
			{ label: "EPC Services", value: true },
		],
		parkingOptions: [
			{ label: "Free Parking", value: "Free Parking" },
			{ label: "Metered Parking", value: "Metered Parking" },
		],
		congestionFeeOptions: [
			{ label: "Not Required", value: false },
			{ label: "Congestion Fee", value: true },
		],
		paymentMethodOptions: [
			{
				label: "Online Payment via Invoice",
				value: "Online Payment via Invoice",
			},
			{ label: "Phone Payment", value: "Phone Payment" },
			{ label: "Cash Payment", value: "Cash Payment" },
		],
	};

	return (
		<>
			<SEO
				title={"S3 Maintenance Services Gas"}
				description={
					"Get your gas certificate quote today from S3 Maintenance Services."
				}
			/>
			<div>
				<section id="page-title" className="background-light text-dark">
					<div className="gas-title">
						<div className="page-title">
							<div className="row">
								<div className="heading-text heading-section">
									<h4 className="text-light">
										All of our Engineers are accredited with the relevant
										schemes
									</h4>
								</div>
								<ul className="grid grid-4-columns border-bottom border-top gas-logos">
									{accreditation.map((item, index) => (
										<li key={index}>
											<a
												aria-label={item.label}
												href={item.link}
												target="blank"
											>
												<img src={item.img} alt={item.label} />
											</a>
										</li>
									))}
								</ul>
							</div>
							<h1 className="m-t-50">Get Your Gas Certificate Quote Today!</h1>
							<h5 className="text gas-entry-text">
								At S3, our comprehensive gas safety certificate services ensure
								that your property meets all the necessary standards and regulations.
								<br />
								<br />
								Additionally, we offer detailed safety checks, Electrical Certificate (EICR), PAT testing and EPC services. We provide peace
								of mind when it comes to the safety and efficiency of your appliances and installations.
								<br />
								<br />
								Choose us, and rest easy knowing your
								gas safety needs are in professional hands.
							</h5>
						</div>
						<div style={{ marginTop: "1.2rem" }} className="breadcrumb">
							<ul>
								<li>
									<Link to="/">Home</Link>
								</li>
								<li>
									<Link to="/services">Services</Link>
								</li>
								<li className="active text-light">
									Get Your Gas Certificate Quote
								</li>
							</ul>
						</div>
					</div>
				</section>
				<section>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<h2 className="text-uppercase">Get Your Quote</h2>
								<span
									style={{ fontSize: "1.3rem" }}
									className="m-l-5 text-primary"
								>
									Required fields *
								</span>
								<div className="line divider" />
								<form className="widget-contact-form" onSubmit={submitForm}>
									<div className="row">
										<label className="text-bold">Your details</label>
										<div className="form-group col-md-6">
											<label>
												First Name{" "}
												<span
													style={{ fontSize: "1.3rem" }}
													className="m-l-5 text-primary"
												>
													*
												</span>
											</label>
											<input
												type="text"
												className="form-control"
												required
												value={form.firstName}
												onChange={handleChange("firstName")}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>
												Last Name{" "}
												<span
													style={{ fontSize: "1.3rem" }}
													className="m-l-5 text-primary"
												>
													*
												</span>
											</label>
											<input
												type="text"
												className="form-control"
												required
												value={form.lastName}
												onChange={handleChange("lastName")}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>
												Your Email{" "}
												<span
													style={{ fontSize: "1.3rem" }}
													className="m-l-5 text-primary"
												>
													*
												</span>
											</label>
											<input
												type="email"
												className="form-control"
												required
												value={form.email}
												onChange={handleChange("email")}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>
												Phone Number
												{/* <span  style={{fontSize:"1.3rem"}}className="m-l-5 text-primary">*</span> */}
											</label>
											<input
												type="tel"
												className="form-control"
												value={form.phone}
												onChange={handleChange("phone")}
											/>
										</div>

										<div className="line divider" />
										<label className="text-bold">Preferred date and time</label>

										<div className="form-group col-md-6">
											<label>
												Preferred Date
												<span
													style={{ fontSize: "1.3rem" }}
													className="m-l-5 text-primary"
												>
													*
												</span>{" "}
											</label>
											<DatePicker
												className="form-control"
												selected={form.preferredDate}
												onChange={(date) =>
													setForm((prevForm) => ({
														...prevForm,
														preferredDate: date,
													}))
												}
												dateFormat="dd/MM/yyyy"
												placeholderText="Select a date"
												required
											/>
										</div>
										<div className="form-group col-md-6">
											<label>Preferred Time Slot</label>
											<Selector
												options={timeSlots}
												onChange={(value) =>
													setForm((prevForm) => ({
														...prevForm,
														preferredTime: value,
													}))
												}
												value={form.preferredTime}
											/>
										</div>

										<div className="line divider" />

										<div className="form-group col-md-12">
											<label className="text-bold">Address for Services</label>
											<label>Address</label>
											<input
												type="text"
												className="form-control"
												placeholder="Search for your Address"
												value={form.address.all}
												onChange={handleChange("address.all")}
												onKeyUp={(e) =>
													getPlacePredictions({ input: e.target.value })
												}
												onFocus={() => setShowDropdown(true)}
											/>
											{isPlacePredictionsLoading && <div>Loading...</div>}
											{showDropdown && placePredictions.length > 0 && (
												<div className="dropdown-menu show">
													{placePredictions.map((item) => renderItem(item))}
												</div>
											)}
										</div>
										<div className="form-group col-md-4">
											<label>
												First Line Address{" "}
												<span
													style={{ fontSize: "1.3rem" }}
													className="m-l-5 text-primary"
												>
													*
												</span>
											</label>
											<input
												type="text"
												className="form-control"
												required
												value={form.address.line1}
												onChange={handleChange("address.line1")}
											/>
										</div>
										<div className="form-group col-md-4">
											<label>City</label>
											<input
												type="text"
												className="form-control"
												value={form.address.city}
												onChange={handleChange("address.city")}
											/>
										</div>
										<div className="form-group col-md-4">
											<label>County / State / Region</label>
											<input
												type="text"
												className="form-control"
												value={form.address.county}
												onChange={handleChange("address.county")}
											/>
										</div>
										<div className="form-group col-md-4">
											<label>
												Post Code / ZIP code{" "}
												<span
													style={{ fontSize: "1.2rem" }}
													className="m-l-5 text-primary"
												>
													*
												</span>
											</label>
											<input
												type="text"
												className="form-control"
												required
												value={form.address.zip}
												onChange={handleChange("address.zip")}
											/>
										</div>

										<div className="line divider" />

										<div className="form-group col-md-12">
											<label className="text-bold">Services Required</label>
											<div style={{ paddingBottom: "1rem" }}>
												<label>
													Gas Certificate CP12{" "}
													<span
														style={{ fontSize: "1.3rem" }}
														className="m-l-5 text-primary"
													>
														*
													</span>
												</label>
												<Selector
													options={serviceOptions.gasCertificationOptions}
													onChange={(value) =>
														setForm((prevForm) => ({
															...prevForm,
															gasCertification: value === "true",
														}))
													}
													value={form.gasCertification}
													required
												/>
											</div>
											<div style={{ paddingBottom: "1rem" }}>
												<label>
													Electrical Certificate (EICR)
													{/* <span  style={{fontSize:"1.3rem"}}className="m-l-5 text-primary">*</span> */}
												</label>
												<Selector
													options={serviceOptions.electricalCertificateOptions}
													onChange={(value) =>
														setForm((prevForm) => ({
															...prevForm,
															electricalCertificate: value === "true",
														}))
													}
													value={form.electricalCertificate}
												/>
											</div>
											<div style={{ paddingBottom: "1rem" }}>
												<label>
													PAT Testing
													{/* <span  style={{fontSize:"1.3rem"}}className="m-l-5 text-primary">*</span> */}
												</label>
												<Selector
													options={serviceOptions.patTestingOptions}
													onChange={(value) =>
														setForm((prevForm) => ({
															...prevForm,
															patTesting: value === "true",
														}))
													}
													value={form.patTesting}
												/>
											</div>
											<div style={{ paddingBottom: "1rem" }}>
												<label>
													EPC Services
													{/* <span  style={{fontSize:"1.3rem"}}className="m-l-5 text-primary">*</span> */}
												</label>
												<Selector
													options={serviceOptions.epcServicesOptions}
													onChange={(value) =>
														setForm((prevForm) => ({
															...prevForm,
															epcServices: value === "true",
														}))
													}
													value={form.epcServices}
												/>
											</div>
										</div>

										<div className="line divider" />

										<label className="text-bold">Other information</label>
										<div className="form-group col-md-12">
											<label>Parking Information</label>
											<Selector
												options={serviceOptions.parkingOptions}
												onChange={(value) =>
													setForm((prevForm) => ({
														...prevForm,
														parking: value,
													}))
												}
												value={form.parking}
											/>
										</div>
										<div
											className="form-group col-md-12"
											style={{ paddingBottom: "1rem" }}
										>
											<label>Payment method</label>
											<Selector
												options={serviceOptions.paymentMethodOptions}
												onChange={(value) =>
													setForm((prevForm) => ({
														...prevForm,
														paymentMethod: value,
													}))
												}
												value={form.paymentMethod}
											/>
										</div>
										<div className="form-group col-md-12">
											<label>Additional Information</label>
											<textarea
												className="form-control"
												rows="5"
												placeholder="Use this space to list any additional information."
												value={form.additionalInfo}
												onChange={handleChange("additionalInfo")}
											></textarea>
										</div>

										<div className="line divider" />

										<button
											style={{ fontSize: "1rem" }}
											className="btn btn-primary"
											type="submit"
											disabled={!form.isValid || isSubmitting}
										>
											<i className="fa fa-paper-plane"></i>
											&nbsp;Submit Request
										</button>
									</div>
								</form>
								<div className={color + " form-group col-md-12"}>
									{statusMessage}
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export default Gas;
