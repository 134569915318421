import React from 'react';
import { useNavigate } from "react-router-dom"

const GoBack = () => {

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <button className='btn-light btn' onClick={goBack}>Go back</button>
    );
};

export default GoBack;