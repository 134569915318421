import React from 'react';
import CountUp from 'react-countup';
import { useInView } from "react-intersection-observer";

import './counters.css';

const data = {
    counter: [
    {
    logo: '1.png',
    name: 'Sites in UK',
    end: 220
    },
    {
    logo: '2.png',
    name: 'Vans',
    end: 30
    },
    {
    logo: '3.png',
    name: 'Properties Managed',
    end: 7000
    },
    {
    logo: '4.png',
    name: 'Satisfied Clients',
    end: 20
    }]
};

const Counter = (div) => {
//   const [count, setCount] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  return (
            <>
              <span ref={ref}/>
                {data.counter.map((counter, index) => (

                    <div key={index} className={"col-lg-" + div.div}>
                      
                        <div className="text-center">
                            <div className="icon"><img src={require("../../images/icons/"+div.blackWhite + counter.logo)} alt="icon" /></div>
                            <div className="counter"> </div>
                            <h2>
                              <CountUp
                              start={inView ? 1 : 1}
                              end={inView ? counter.end:1 }
                            //   delay={0}
                              duration={5}
                              // separator=" "
                              scrollSpyOnce={true}
                              preserveValue={true}
                              decimals={0}
                            //   decimal=","
                              // enableScrollSpy={true}
                              scrollSpyDelay={300}
                            //   suffix="right"
                            // onEnd={() => console.log('Ended! 👏')}
                            // onStart={() => console.log('Started! 💨')}
                            />+</h2>
                            <div className="seperator seperator-small"></div>
                            <h5>{counter.name}</h5>
                        </div>
                    </div>
                    ))}
      </>
  );
};

export default Counter;
