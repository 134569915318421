import React from 'react';


const ActionProvider = ({ createChatBotMessage, setState, children, dispatch }) => {

  const actions = {
    handleServices: () => {
      const botMessage = createChatBotMessage(`We offer a wide range of services. Visit our Services page to learn more:`, {
        widget: 'NavToServices',
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handleQuotes: () => {
      const botMessage = createChatBotMessage('Get a quote for your specific needs! Visit our Quotes page:', {
        widget: 'NavToQuotes'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handleProjects: () => {
      const botMessage = createChatBotMessage('See our past work and expertise!', {
        widget: 'NavToProjects',
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handleClients: () => {
      const botMessage = createChatBotMessage('Discover some of our valued clients!', {
        widget: 'NavToClients'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handleAbout: () => {
      const botMessage = createChatBotMessage('Learn more about us!', {
        widget: 'NavToAbout',
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handleBlog: () => {
      const botMessage = createChatBotMessage('Stay informed with our latest articles!', {
        widget: 'NavToBlog'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handleContact: () => {
      const botMessage = createChatBotMessage('Get in touch with us!', {
        widget: 'NavToContact'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handlePricing: () => {
      const botMessage = createChatBotMessage('Check out our pricing options:', {
        widget: 'NavToPricing'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handleSitemap: () => {
      const botMessage = createChatBotMessage('Here\'s our sitemap for easy navigation:', {
        widget: 'NavToSitemap'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handleTerms: () => {
      const botMessage = createChatBotMessage('Review our terms and conditions:', {
        widget: 'NavToTerms'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handleCareers: () => {
      const botMessage = createChatBotMessage('Explore career opportunities with us!', {
        widget: 'NavToCareers'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handleComplaints: () => {
      const botMessage = createChatBotMessage('We value your feedback. Share your complaint here:', {
        widget: 'NavToComplaints'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handleFaqs: () => {
      const botMessage = createChatBotMessage('Find answers to frequently asked questions!', {
        widget: 'NavToFaqs'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    
    handlePrivacy: () => {
      const botMessage = createChatBotMessage('Review our privacy policy:', {
        widget: 'NavToPrivacy'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    handleSalutation: () => {
      const botMessage = createChatBotMessage('Hello, with what can i help you today? Are any of this links of use to you?', {
        widget: 'DisplayRandomLinks'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage]
      }));
    },
    handleDefault: () => {
      const botMessage = createChatBotMessage('Sorry, I didn\'t understand. Perhaps one of these links might help.', {
        widget: 'DisplayRandomLinks'
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    },
  };

  
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, { actions });
      })}
    </div>
  );
};

export default ActionProvider;

