import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

const SortableTable = ({ data, config, sortTable }) => {
    const [sortField, setSortField] = useState(null);
    const [ascending, setAscending] = useState(true);
    const navigate = useNavigate();

    const handleSort = (field) => {
        const toggleSort = sortField === field;
        setSortField(field);
        const newAscending = toggleSort ? !ascending : true;
        setAscending(newAscending);
        sortTable(field, newAscending);
    };

    const renderTableHeaders = () => {
        return config.map((field) => (
            <th
                key={field.name}
                onClick={() => handleSort(field.key)}
                style={{ cursor: 'pointer' }}
            >
                {field.name}
            </th>
        ));
    };

    const handleCellClick = (e, item) => {
        e.preventDefault();
        const blogSlug = e.target.id;
        if (item.currentPosition !== undefined ) {
            navigate(`/careersportal/applicants/${blogSlug}`, {
                state: { item },
            });
        } else if (item.subscription !== undefined) {
            navigate(`/portal/quotes/${blogSlug}`, {
                state: { item },
            });
        } else {
            navigate(`/portal/complaints/${blogSlug}`, {
                state: { item },
            });
        }
    };

    const renderTableBody = useMemo(() => {
        return data.map((item, index) => (
            <tr className='table-row' key={index}>
                {config.map((fieldConfig) => {
                    if (fieldConfig.key === 'createdAt') {
                        const formattedDate = new Date(item[fieldConfig.key]).toLocaleString();
                        return (
                            <td
                                id={item._id}
                                onClick={(e) => {handleCellClick(e, item)}}
                                key={fieldConfig.key}
                                className='table-content-pointer'
                            >
                                {formattedDate}
                            </td>
                        );
                    } else {
                        return (
                            <td
                                id={item._id}
                                onClick={(e) => {handleCellClick(e, item)}}
                                key={fieldConfig.key}
                                className='table-content-pointer'
                            >
                                {item[fieldConfig.key]}
                            </td>
                        );
                    }
                })}
            </tr>
        ));
    }, [data, config]);

    return (
        <table className="table table-hover">
            <thead>
                <tr>{renderTableHeaders()}</tr>
            </thead>
            <tbody>{renderTableBody}</tbody>
        </table>
    );
};

export default SortableTable;
