import { createChatBotMessage } from 'react-chatbot-kit';
import {
  NavToServices,
  NavToQuotes,
  NavToProjects,
  NavToClients,
  NavToAbout,
  NavToBlog,
  NavToContact,
  NavToPricing,
  NavToSitemap,
  NavToTerms,
  NavToCareers,
  NavToComplaints,
  NavToFaqs,
  NavToPrivacy
} from './Components/LinkComponent/displaySingleLink.jsx';
import { DisplayMultipleLinks, DisplayRandomLinks } from './Components/LinkComponent/displayMultipleLinks.jsx';


const config = {
  initialMessages: [
    createChatBotMessage(`Hi! I'm your S3 assistant, how can i help you today?`),
    createChatBotMessage(`Here are some options you might like:`, {
      widget: 'DisplayMultipleLinks'
    })
  ],
  widgets: [
    { widgetName: 'NavToServices', widgetFunc: () => <NavToServices /> },
    { widgetName: 'NavToQuotes', widgetFunc: () => <NavToQuotes /> },
    { widgetName: 'NavToProjects', widgetFunc: () => <NavToProjects /> },
    { widgetName: 'NavToClients', widgetFunc: () => <NavToClients /> },
    { widgetName: 'NavToAbout', widgetFunc: () => <NavToAbout /> },
    { widgetName: 'NavToBlog', widgetFunc: () => <NavToBlog /> },
    { widgetName: 'NavToContact', widgetFunc: () => <NavToContact /> },
    { widgetName: 'NavToPricing', widgetFunc: () => <NavToPricing /> },
    { widgetName: 'NavToSitemap', widgetFunc: () => <NavToSitemap /> },
    { widgetName: 'NavToTerms', widgetFunc: () => <NavToTerms /> },
    { widgetName: 'NavToCareers', widgetFunc: () => <NavToCareers /> },
    { widgetName: 'NavToComplaints', widgetFunc: () => <NavToComplaints /> },
    { widgetName: 'NavToFaqs', widgetFunc: () => <NavToFaqs /> },
    { widgetName: 'NavToPrivacy', widgetFunc: () => <NavToPrivacy /> },
    { widgetName: 'DisplayMultipleLinks', widgetFunc: () => <DisplayMultipleLinks /> },
    { widgetName: 'DisplayRandomLinks', widgetFunc: () => <DisplayRandomLinks /> }
  ],
};

export default config;