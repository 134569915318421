import React, { useEffect, useState, useRef } from 'react';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { geoMercator } from "d3-geo";
import { scaleQuantile } from "d3-scale";
import england from "./england.json";
import wales from "./wales.json";
import scotland from "./scotland.json";
import ireland from "./ireland-provinces.json";
import northernIreland from "./nireland_all.json";

import "./map.css";

const generateRandomData = (...geojsons) => {
  return geojsons.flatMap(geojson => {
    // Check if 'eer' property exists; if not, use 'wps'
    const geometryKey = geojson.objects.eer ? 'eer' : 'wpc';
    return geojson.objects[geometryKey].geometries.map((feature, idx) => {
      // Determine the appropriate property for the name; prefer EER13NM, fallback to NAME, then to WPS13NM
      const name = feature.properties.EER13NM || feature.properties.NAME || feature.properties.PC_NAME;
      return {
        id: feature.id,
        selected_region: idx % 5 ? idx : "",
        name: name
      };
    });
  });
};

const Map = () => {
  const [data, setData] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const svgRef = useRef(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [svgSize, setSvgSize] = useState({
    width: 900,
    height: 900,
  });

  let projection = geoMercator();

  const handleSelect = (event) => {
    setSelectedName(event.target.value);
  };

  useEffect(() => {
    function updateSize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    function updateSvgSize() {
      setSvgSize({
        width: svgRef.current.clientWidth,
        height: svgRef.current.clientHeight,
      });
    }

    window.addEventListener('resize', updateSvgSize);
    updateSize();
    updateSvgSize();

    return () => window.removeEventListener('resize', updateSvgSize);
  }, []);

  let height, width;
  let move = -5.5;

  if (svgRef.current) {
    if (windowSize.width < 400 || windowSize.height < 400) {
      width = 740;
      height = 740;
      move = -4.8;
    } else if (windowSize.width < 900 ){
      width = 800;
      height = 800;
      move = -4.5;
    }else {
      width = 800;
      height = 800;
    }
  } else {
    width = svgSize.width;
    height = svgSize.height;
  }

  const proportion = width / height;
  projection = geoMercator();
  projection
    .scale(proportion * (height * 5.5))
    .center([move, 52.9])
    .translate([width / 2, height / 2]);

  useEffect(() => {
    const randomData = generateRandomData(england, wales, scotland, northernIreland, ireland);
    setData(randomData);
  }, [windowSize]);

  const colorScale = scaleQuantile()
    .domain(data.map(d => d.selected_region))
    .range([
      "var(--white)",
      "var(--grey)",
      "var(--grey)",
      "var(--white)",
      "var(--grey)",
    ]);

  const getNamesForColor = (color) => {
    const unemploymentRatesForColor = data.filter(d => colorScale(d.selected_region) === color).map(d => d.selected_region);

    const extendGeometriesWithCountry = (geometries, countryName) =>
      geometries.map(g => ({ ...g, country: countryName }));

    const geometriesForColor = [].concat(
      extendGeometriesWithCountry(england.objects.eer.geometries, "England"),
      extendGeometriesWithCountry(wales.objects.eer.geometries, "Wales"),
      extendGeometriesWithCountry(scotland.objects.eer.geometries, "Scotland"),
      extendGeometriesWithCountry(ireland.objects.wpc.geometries, "Ireland"),
      extendGeometriesWithCountry(northernIreland.objects.wpc.geometries, "Northern Ireland")
    ).filter(g => {
      const dataEntry = data.find(d => d.id === g.id);
      return dataEntry && unemploymentRatesForColor.includes(dataEntry.selected_region);
    });

    // Map each geometry to an object containing the name and country
    return geometriesForColor.map(g => {
      const name = g.properties.PC_NAME || g.properties.NAME || g.properties.EER13NM;
      return { name, country: g.country };
    });
  };


  const namesForGray = getNamesForColor("var(--grey)");

  const getStrokeStyles = (geo, fillColor) => {
    const isSelected = geo.properties.EER13NM === selectedName || geo.properties.NAME === selectedName || geo.properties.PC_NAME === selectedName;
    const isGrayBorough = fillColor === "var(--grey)";
    const isWhiteBorough = fillColor === "var(--white)"; // Add a flag for white boroughs

    if (isWhiteBorough || isSelected) {
      return {
      default: {
        outline: "none",
        strokeWidth: isGrayBorough ? 1 : .5 // Highlight stroke if selected and gray
      },
      hover: {
        outline: "none",
        strokeWidth: .5,
      },
      pressed: {
        outline: "none",
        fill: "var(--main)", 
        strokeWidth: isGrayBorough ? 1 : .15
      }
    };
  }else{
      return {
        default: {
          outline: "none",
          strokeWidth: isGrayBorough ? 1 : .5 // Highlight stroke if selected and gray
        },
        hover: {
          outline: "none",
          strokeWidth: 1.5,
          fill: "var(--grey-dark)" 
        },
        pressed: {
          outline: "none",
          fill: "var(--main)", 
          strokeWidth: isGrayBorough ? 1 : .15
        }
      };
    }
  };

  return (
    <>
    <div className="map-housing container">
      <div className="m-t-20 m-b-0 map-title heading-text heading-section col-lg-4">
        <h2>We operate in all of the UK and Ireland</h2>
        <h5>
          Customised Services Nationwide
        </h5>
        <p className='map-message'>We understand the unique needs of different regions and tailor our services to meet the local demands of your properties, ensuring compliance with regional regulations and standards. Our ability to adapt and respond to diverse scenarios makes us your reliable partner for property maintenance nationwide.</p>
        <div className="dropdown">
          <h2 className='map-regions'>
            <small>Regions where we operate</small>
          </h2>

          <div className="input-group mb-5">
            <div className="input-group-prepend">
              <label className="input-group-text"  aria-label='Regions'>Regions</label>
            </div>
            <select
              className="boroughs-dropdown regions custom-select"
              name="boroughs"
              id="boroughs"
              value={selectedName}
              onChange={handleSelect}
              aria-label='Regions'
            >
              {namesForGray.map((value, index) => (
                <option className='regions' key={index} value={value.name}>
                 {value.country} - {value.name}&nbsp;
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className='cointainer-map'>
        <ComposableMap
          ref={svgRef}
          projection={projection}
        >
          <Geographies
            className='england'
            geography={england}
          >
            {({ geographies }) =>
              geographies.map(geo => {
                const cur = data.find(s => s.id === geo.id);
                let fillColor = cur ? colorScale(cur.selected_region) : "#EEE";

                if (geo.properties.EER13NM === selectedName) {
                  fillColor = "var(--main)";
                }

                const isGrayBorough = fillColor === "var(--grey)";

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={fillColor}
                    stroke="var(--grey-dark)"
                    strokeWidth={.2}
                    style={getStrokeStyles(geo, fillColor)}
                    onClick={() => {
                      if (isGrayBorough) {
                        setSelectedName(geo.properties.EER13NM);
                      }
                    }}
                  />
                );
              })
            }
          </Geographies>

          <Geographies
            className='country'
            geography={wales}
          >
            {({ geographies }) =>
              geographies.map(geo => {
                const cur = data.find(s => s.id === geo.id);
                let fillColor = cur ? colorScale(cur.selected_region) : "#EEE";

                if (geo.properties.EER13NM === selectedName) {
                  fillColor = "var(--main)";
                }

                const isGrayBorough = fillColor === "var(--grey)";

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={fillColor}
                    stroke="var(--grey-dark)"
                    strokeWidth={.2}
                    style={getStrokeStyles(geo, fillColor)}
                    onClick={() => {
                      if (isGrayBorough) {
                        setSelectedName(geo.properties.EER13NM);
                      }
                    }}
                  />
                );
              })
            }
          </Geographies>

          <Geographies
            className='country'
            geography={scotland}
          >
            {({ geographies }) =>
              geographies.map(geo => {
                const cur = data.find(s => s.id === geo.id);
                let fillColor = cur ? colorScale(cur.selected_region) : "#EEE";

                if (geo.properties.EER13NM === selectedName) {
                  fillColor = "var(--main)";
                }

                const isGrayBorough = fillColor === "var(--grey)";

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={fillColor}
                    stroke="var(--grey-dark)"
                    strokeWidth={.2}
                    style={getStrokeStyles(geo, fillColor)}
                    onClick={() => {
                      if (isGrayBorough) {
                        setSelectedName(geo.properties.EER13NM);
                      }
                    }}
                  />
                );
              })
            }
          </Geographies>

          <Geographies
            className='country'
            geography={ireland}
          >
            {({ geographies }) =>
              geographies.map(geo => {
                const cur = data.find(s => s.id === geo.id);
                let fillColor = cur ? colorScale(cur.selected_region) : "#EEE";

                if ( geo.properties.NAME === selectedName) {
                  fillColor = "var(--main)";
                }

                const isGrayBorough = fillColor === "var(--grey)";

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={fillColor}
                    stroke="var(--grey-dark)"
                    strokeWidth={.2}
                    style={getStrokeStyles(geo, fillColor)}
                    onClick={() => {
                      if (isGrayBorough) {
                        setSelectedName(geo.properties.NAME);
                      }
                    }}
                  />
                );
              })
            }
          </Geographies>

          <Geographies
            className='country'
            geography={northernIreland}
          >
            {({ geographies }) =>
              geographies.map(geo => {
                const cur = data.find(s => s.id === geo.id);
                let fillColor = cur ? colorScale(cur.selected_region) : "#EEE";

                if (geo.properties.PC_NAME === selectedName) {
                  fillColor = "var(--main)";
                }

                const isGrayBorough = fillColor === "var(--grey)";

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={fillColor}
                    stroke="var(--grey-dark)"
                    strokeWidth={.2}
                    style={getStrokeStyles(geo, fillColor)}
                    onClick={() => {
                      if (isGrayBorough) {
                        setSelectedName(geo.properties.PC_NAME);
                      }
                    }}
                  />
                );
              })
            }
          </Geographies>

        </ComposableMap>
      </div>
    </div>
      <div className='legend' style={{paddingTop:"3rem"}}>
          <div className='legend-item btn btn-dark' 
          style={{backgroundColor: 'var(--grey)', border:"1px solid var(--grey)"}}
          >
            Regions we operate in
          </div>
          {/* <div className='legend-item btn btn-dark' 
          style={{backgroundColor: 'var(--white)', color:"var(--dark-grey)", border:"1px solid var(--grey-light)"}}
          >
            Where we don't operate
          </div> */}
          <div className='legend-item btn btn-dark' 
          style={{backgroundColor: 'var(--main)', color:"var(--dark-grey)", border:"1px solid var(--main)"}}
          >
            Selected region
          </div>
    </div>
    </>
  );
};

export default Map;
