import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import "../login.css"; // Path may need adjusting based on your structure
import SpinnerLoading from '../../../components/spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const ComplaintSingle = () => {
    const axiosPrivate = useAxiosPrivate();
    const { state } = useLocation();
    const complaint = state.item; // Ensure that the item passed in state is a complaint object
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleComplaintDelete = async () => {
        try {
            // Make request to delete the complaint
            await axiosPrivate.put(`/complaint/delete/${complaint._id}`);
            // Notify success and navigate back
            toast.success('Complaint deleted successfully');
            navigate(-1);
        } catch (err) {
            toast.error('An error occurred while deleting the complaint');
        }
    };

    function openMailto() {
        const recipient = complaint.email;
        const subject = "Your Complaint with S3 Maintenance Services";
        const body = "Dear " + complaint.name + ",\n\n" + "Thank you for submitting your complaint. We have received your feedback and appreciate you bringing this matter to our attention. We will investigate your concerns thoroughly and take appropriate steps to address the issue.\n\n" + "Please expect a response from our team within 2-3 business days. If you have any further questions or information to share, don't hesitate to contact us.\n\n" + "Sincerely,\n\n" + "S3 Maintenance Services Team";
        
        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        
        window.location.href = mailtoLink;
        }
    return (
        <>
            <div className="container-login applicant p-r-20">
                <div className="card-header">
                    {/* Page Heading */}
                    <div className="heading-text heading-gradient">
                        <h2 style={{ fontSize: "2.5rem" }}>
                            <span>Complaint Details</span>
                        </h2>
                    </div>
                    <p className="text-primary">Details of the complaint are shown below</p>
                </div>

                {/* Complaint Details */}
                <div className="card-header">
                    <h4 className='text-uppercase'>Name</h4>
                    <p>{complaint.name}</p>

                    <h4 className='text-uppercase'>Email</h4>
                    <p>{complaint.email}</p>

                    <h4 className='text-uppercase'>Phone</h4>
                    <p>{complaint.phone}</p>

                    <h4 className='text-uppercase'>Message</h4>
                    <p>{complaint.message}</p>

                    <h4 className='text-uppercase'>Created At</h4>
                    <p>{new Date(complaint.createdAt).toLocaleDateString()}</p>
                </div>
            </div>

            {/* Confirmation Modal */}
            <Modal centered show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this complaint?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleComplaintDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>

            {isLoading ? <SpinnerLoading /> : (
                <div className='buttons-quotes'>
                    <button
                        style={{ height: "50px", width: "200px", margin: "20px" }}
                        className="btn btn-danger"
                        onClick={handleShow}
                    >
                        Delete Complaint
                    </button>
                    <button
                            style={{ height: "50px", width: "200px", margin: "20px" }}
                            className="btn btn-secondary"  onClick={openMailto}>
                            Send Email 
                    </button>
                </div>
            )}
        </>
    );
};

export default ComplaintSingle;