import React, { useEffect, useState } from 'react';
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
// import SvgBoxFromDiv from '../../components/Particles/boxTosvg';

import "./particles.css";

const Particle = (details) => {
  const [init, setInit] = useState(false);
  // const [svg, setSvg] = useState(details.svgs[0]);

  // const element = <SvgBoxFromDiv targetRef={svg} />

  const particlesConfig = {
    fps_limit: 60,
    interactivity: {
      detect_on: "canvas",
      events: {
        // onClick: { enable: true, mode: "push" },
        onHover: {
          enable: true,
          mode: "attract",
          parallax: { enable: false, force: 60, smooth: 10 }
        },
        resize: true
      },
      modes: {
        push: { quantity: 10 },
        attract: { distance: 200, duration: 0.4, factor: 6 }
      }
    },
    fullScreen: {
      enable: false,
    },
    particles: {
      color: { value: details.color },
      links: {
        color: details.color,
        distance: 150,
        enable: true,
        opacity: details.opacity,
        width: 1
      },
      move: {
        attract: { enable: false, rotateX: 600, rotateY: 1200 },
        bounce: false,
        direction: "none",
        enable: true,
        outMode: "out",
        random: false,
        speed: 2,
        straight: false
      },
      number: { density: { enable: true, value_area: 200 }, value: 100 },
      opacity: {
        anim: { enable: false, minimumValue: 0.1, speed: 1, sync: false },
        random: false,
        value: details.opacity
      },
      shape: {
        type: "circle",
      },
      size: {
        anim: { enable: false, minimumValue: 0.1, speed: 20, sync: false },
        random: true,
        value: 1.5
      }
    },
    detectRetina: true,
    polygon: {
      enable: true,
      type: 'inline',
      move: {
        radius: 10,
      },
      inline: {
        arrangement: 'equidistant',
      },
      draw: {
        enable: true,
        stroke: {
          color: 'rgba(255, 255, 255, .2)',
        },
      },
      url: "#svgBound",
    },
  };

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  return (
    <>
    {/* {element} */}
    {/* <SvgBoxFromDiv targetRef={svg} /> */}
    <div className='particles-container'>
      {init && <Particles
        id="tsparticles"
        options={particlesConfig}
      />}
      <div className='background-particles'/>
    </div>
    </>
  );
};

export default Particle;
