import React, { useState} from 'react';
import {useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

import "../login.css"; 
import SpinnerLoading from '../../../components/spinner';

const ApplicantSingle = () => {
    const axiosPrivate = useAxiosPrivate();
    // const { slug } = useParams(); 
    const { state } = useLocation();
    const application = state.item; 

    const [isLoading, setIsLoading] = useState(false);
    const [formData] = useState({
        name: application.name,
        email: application.email,
        phone: application.phone,
        position: application.position,
        currentPosition: application.currentPosition,
        coverLetter: application.coverLetter, 
        // We don't need to edit the file directly
    });


    const handleFileDownload = async () => {
        setIsLoading(true);
        if (!application.file) {
            toast.error('No file associated with this application');
            setIsLoading(false);
            return;
        }
        try {
            const response = await axiosPrivate.get(`/application/download/${application._id}`);
            const downloadUrl = response.data.url; // The backend should send the pre-signed URL 

            // Download directly using the link
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', application.file.fileName); 
            link.target = '_blank'; // Open in a new window/tab
            document.body.appendChild(link); 
            link.click(); 
            link.remove(); 
            toast.success('CV downloading...');
            setIsLoading(false);
        } catch (error) {
            toast.error('Failed to download file');
            setIsLoading(false);
        }
    };

    return (
    <>
        <div className="container-login applicant p-r-20"> 
                        <div className="card-header">
                    <div className="heading-text heading-gradient">
                        <h2 style={{fontSize:"2.5rem"}}>
                            <span>Applicant information</span>
                        </h2>
                    </div>
                    <p className="text-primary">Applicant's details below, download CV by clicking in button</p>
                </div>
            {Object.entries(formData).map(([key, value]) => (
                <div className="card-header" key={key}>
                    <h4 className='text-uppercase'>{key}</h4>
                    <p>{value}</p>
                </div>
            ))}
        </div>
            {isLoading ? <SpinnerLoading /> : (
                <> 
                    {application.file && (
                        <button
                        style={{height: "50px", width: "200px", margin: "20px"}}
                        type="button" className="btn btn-primary" onClick={handleFileDownload}>
                        <i className="fa fa-download"
                        style={{paddingRight: "10px"}}
                        ></i>    
                            Download Resume
                        </button>
                    )}
                </>
            )}
        </>
    );
};

export default ApplicantSingle;
