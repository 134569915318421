import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendEmail } from "../../services/sendMail";
import "./complaints.css";
import axios from '../../hooks/api';
import SpinnerLoading from "../../components/spinner";
import SEO from '../../components/SEO.jsx';

const Complaints = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [isValid, setIsValid] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [color, setColor] = useState('text-primary');

    const navigate = useNavigate();

    const validateForm = () => {
        setIsValid(name !== "" && email !== "" && phone !== "" && message !== "");
    };

    const submitForm = async (e) => {
        e.preventDefault();
        if (!isValid) return;
        const formData = {
            name,
            email,
            phone,
            message,
        };

        try {
            const response = await axios.post('/fe/complaint', formData)
            if (response.status === 201) {
                setStatusMessage('Quote submitted sucessfully!');
                setColor('text-success');
            } else {
                setStatusMessage('Quote submission failed');
                setColor('text-danger');
            }
        } catch (err) {
            console.error(err);
        } 
  
        try {
            await sendEmail("complaints", formData).then(() => {
                setIsValid(false);
                navigate("/confirmation");
                window.scrollTo(0, 0);
            });
        } catch (error) {
            setIsValid(false);
            navigate("/request-error");
            window.scrollTo(0, 0);
            console.error(error);
        }finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
        <SEO title={"S3 Maintenance Services Complaints"} description={"S3 Maintenance Services is here to listen to any problems you might have had. Please don't hesitate to contact us."}/>
    
            <div>
                <section id="page-title" className="background-grey-dark text-light">
                    <div className="container">
                        <div className="page-title">
                            <h1>Submit Your Complaint</h1>
                        </div>
                        <div className="breadcrumb">
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="active text-light">Complaints</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3 className="text-uppercase">Submit Your Complaint</h3>
                                <p>Please share your concern with us and we will get back to you promptly.</p>
                                <form onSubmit={submitForm}>
                                    <div className="row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="name">Name*</label>
                                            <input
                                                type="text"
                                                required
                                                className="form-control required"
                                                placeholder="Enter your name"
                                                onChange={(e) => setName(e.target.value)}
                                                onBlur={validateForm}
                                            />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="email">Email*</label>
                                            <input
                                                type="email"
                                                required
                                                className="form-control required email"
                                                placeholder="Enter your email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                onBlur={validateForm}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="phone">Phone Number*</label>
                                            <input
                                                type="text"
                                                required
                                                className="form-control"
                                                placeholder="Enter your phone number"
                                                onChange={(e) => setPhone(e.target.value)}
                                                onBlur={validateForm}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Complaint Details*</label>
                                        <textarea
                                            required
                                            rows="5"
                                            className="form-control"
                                            placeholder="Describe the issue"
                                            onChange={(e) => setMessage(e.target.value)}
                                            onBlur={validateForm}
                                        ></textarea>
                                    </div>
                                    <button className="btn btn-primary" type="submit" disabled={!isValid}>
                                        <i className="fa fa-paper-plane"></i>
                                        &nbsp;Submit Complaint
                                    </button>
                                    {isSubmitting ? (
                                        <SpinnerLoading />
                                    ) : (
                                        <div className={color+" form-group col-md-12"}>
                                                {statusMessage}
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Complaints;
