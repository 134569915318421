import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SortableTable from '../Components/sort.jsx';

const Quotes = () => {
    const [quotes, setQuotes] = useState([]);
    const [filteredQuotes, setFilteredQuotes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const axios = useAxiosPrivate();

    const sortQuotes = (field, ascending) => {
        const sortedData = [...filteredQuotes];
    
        // Generic sorting based on field type
        if (typeof sortedData[0][field] === 'number') {
            sortedData.sort((a, b) => ascending ? a[field] - b[field] : b[field] - a[field]);
        } else if (typeof sortedData[0][field] === 'string') {
            sortedData.sort((a, b) => {
                const valueA = a[field].toLowerCase();
                const valueB = b[field].toLowerCase();
                if (valueA < valueB) return ascending ? -1 : 1;
                if (valueA > valueB) return ascending ? 1 : -1;
                return 0;
            });
        } else if (sortedData[0][field] instanceof Date) {
            sortedData.sort((a, b) => ascending 
                             ? new Date(a[field]) - new Date(b[field])
                             : new Date(b[field]) - new Date(a[field])); 
        }
    
        setFilteredQuotes(sortedData); 
    };

    const TABLE_CONFIG = [
        { key: 'name', name: 'Name' },
        { key: 'company', name: 'Company' },
        { key: 'service', name: 'Service' },
        { key: 'budget', name: 'Budget £' },
        { key: 'date', name: 'Preferred service date' },
        { key: 'createdAt', name: 'Date Submitted' },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/quotes');
                setQuotes(response.data);
            } catch (error) {
                console.error('Error fetching quotes data:', error);
            }
        };
        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setSearchTerm(searchValue);

        const newFilteredQuotes = quotes.filter((quote) => {
            return (
                quote.name.toLowerCase().includes(searchValue) ||
                quote.company.toLowerCase().includes(searchValue) ||
                quote.service.toLowerCase().includes(searchValue) ||
                quote.budget.toString().toLowerCase().includes(searchValue) ||
                quote.date.toLowerCase().includes(searchValue) ||
                quote.createdAt.toLowerCase().includes(searchValue)
            );
        });

        setFilteredQuotes(newFilteredQuotes);
    };

    useEffect(() => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const newFilteredQuotes = quotes.filter((quote) => {
            return (
                quote.name.toLowerCase().includes(lowerCaseSearchTerm) ||
                quote.company.toLowerCase().includes(lowerCaseSearchTerm) ||
                quote.service.toLowerCase().includes(lowerCaseSearchTerm) ||
                quote.budget.toString().toLowerCase().includes(lowerCaseSearchTerm) ||
                quote.date.toLowerCase().includes(lowerCaseSearchTerm) ||
                quote.createdAt.toLowerCase().includes(lowerCaseSearchTerm)
            );
        });

        setFilteredQuotes(newFilteredQuotes);
    }, [searchTerm, quotes]);

    return (
        <section style={{ padding: "4rem", paddingTop: "1rem" }} className="container-login">
            <div className="heading-text heading-gradient">
                <h2 style={{ fontSize: "2.5rem" }}><span>Quotes</span></h2>
            </div>
            <div className="form-group">
                <input
                    className='form-control notification-message"'
                    type="text"
                    placeholder="Search quotes..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            {filteredQuotes.length ? (
                <SortableTable
                    data={filteredQuotes}
                    config={TABLE_CONFIG}
                    sortTable={sortQuotes}
                />
            ) : (
                <p style={{ paddingTop: "5rem" }}>No quotes to display</p>
            )}
        </section>
    );
};

export default Quotes;
