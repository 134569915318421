import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import useAuth from "../../hooks/useAuth";
import "./login.css"

const Dashboard = () => {
    const { auth } = useAuth();
    const roles = auth?.roles;  // Access roles from auth

    return (
            <section className="content-login w-100 px-3 px-sm-5 px-xl-7">
                <div className="mb-5 text-center">
                    <h5 className="h3 mb-1 text-center welcome-message">Welcome to the Admin Dashboard</h5>
                    <p className="m-b-40 text-bold">In this page, you can do the following</p>
                    <ul style={{padding: "0"}}>
                        {/* <li className='link-list'><Link to="/login">Login</Link></li> */}

                        {roles?.includes(2000) ? ( 
                            <>
                            <li className='link-list link-dashboard'><Link className='portal-links' to="/users">Manage Users</Link>                     
                            </li>
                            <p className='justify-center'>Manage accounts, register Users: Create new user accounts (for Admins only).</p>
                            </>
                        ) : (
                            <li className='link-list dahsboard-disabled disabled-link'>Manage Users</li>
                        )}

                        
                        {roles?.includes(2000) ? ( 
                            <>
                            <li className='link-list link-dashboard'>
                                <Link className='portal-links' to="/portal/quotes">Quotes</Link>
                                <p className='justify-center'>View and manage quotes (for Admins only).</p>
                            </li>
                            </>
                        ) : (
                            <li className='link-list dahsboard-disabled disabled-link'>Quotes</li>
                        )}

                        {roles?.includes(2000) ? ( 
                            <li className='link-list link-dashboard'>
                                <Link className='portal-links' to="/portal/complaints">Complaints</Link>
                                <p className='justify-center'>View and manage complaints (for Admins only).</p>
                            </li>
                        ) : (
                            <li className='link-list dahsboard-disabled disabled-link'>Complaints</li>
                        )}
                        <li className='link-list link-dashboard'>
                            <Link className='portal-links' to="/careersportal">Careers</Link>
                            <p className='justify-center'>Careers Editor: Update and manage job postings, see applicants.</p>
                        </li>

                        <li className='link-list link-dashboard'>
                            <Link className='portal-links' to="/blogportal">News Editor</Link>
                            <p className='justify-center'>News Editor: Create and publish news articles.</p>
                        </li>
                    </ul>
                </div>  
                    <div className="mt-4 text-center"><small>Any questions?</small> <Link to={"/contact"} className="small fw-bold">Contact an Admin</Link>
                    </div>
            </section>
    );
};

export default Dashboard;

