import ReactGA from 'react-ga4';

// Multiple products (We will set up as many details as we can)
const initializeGA = () => ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_GA_MEASUREMENT_ID,
    gaOptions: {
      cookieDomain: 'auto', // Automatic cookie domain configuration
      siteSpeedSampleRate: 100, // Sample 100% of hits for site speed measures
      // Any additional, advanced gaOptions you might need can go here.
    }
  }
]);

// To maximize the information tracked, ensure that ReactGA is called to record page views and user interactions:

// Send pageview with detailed information
const sendGA = () => ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search, // Automatically captures the current path and query string
  title: document.title, // Captures the current document title
  location: window.location.href, // Captures the full URL of the page
});

// // Send a detailed custom event
// ReactGA.event({
//   category: "your category", // Category of the event
//   action: "your action", // The action taken by the user
//   label: "your label", // A label to provide additional information (optional)
//   value: 99, // A numeric value associated with the event (optional)
//   nonInteraction: false, // False to ensure this event affects bounce rate calculations
//   transport: "beacon", // Use 'beacon' when you want to ensure the data is sent before the page unloads
//   dimension1: "custom dimension value", // Custom dimension (optional, index must match a custom dimension in GA)
//   metric1: 123, // Custom metric (optional, index must match a custom metric in GA)
// });

export {initializeGA, sendGA}