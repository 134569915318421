import { Helmet } from "react-helmet";
import logo from "../images/logos/logo.png";
import { useLocation } from "react-router-dom";

const SEO = ({ title, description, url, image = logo }) => {
	const location = useLocation();

	if (!url) {
	url = location.pathname;
	}
	// Remove trailing slash if present
	if (url.endsWith('/')) {
	url = url.slice(0, -1);
	}

	return (
		<Helmet>
			<title>{title}</title>
			<meta property="og:title" content={title} />
			<meta
				// property="og:description"
				name="description"
				content={description}
			/>
			{/* <meta property="og:type" content="website" /> */}
			<meta property="og:image" content={image} />
			{/* <meta property="og:url" content={url} /> */}

			<link rel="canonical" href={"https://s3maintenance.co.uk" + url} />
			<meta property="og:type" content="website" data-react-helmet="true"/>
			{/* <meta property="og:image" content="https://www.s3maintenance.co.uk/static/media/logo.734d7eb104791663722f.png" data-react-helmet="true"/> */}
			<meta property="og:url" content={"https://s3maintenance.co.uk" + url} data-react-helmet="true"/> 
			<meta property="og:title" content={`${title} || S3 Maintenance Services`} data-react-helmet="true"/>
			<meta property="og:description" content={description} data-react-helmet="true"/>
			<meta name="twitter:card" content="summary_large_image"/>
			<meta name="twitter:title" content={`${title} || S3 Maintenance Services`}/>
			<meta name="twitter:description"content={description}/>
			<meta name="twitter:image" content="https://www.s3maintenance.co.uk/static/media/logo.734d7eb104791663722f.png"/> 
		</Helmet>
	);
};

export default SEO;
