import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Link, useNavigate} from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import toast from 'react-hot-toast';

const BlogPortal = () => {
    const [blogs, setBlogs] = useState([]); // Renamed to 'blogs'
    const [showModal, setShowModal] = useState(false);
    const [entryToDelete, setEntryToDelete] = useState(null);
    const axios = useAxiosPrivate();
    const navigate = useNavigate();
// Fetch careers info from the server on component mount

useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get('/blog'); // Adjust the API endpoint
            setBlogs(response.data);
            const data = await response.data;
        } catch (error) {
            console.error('Error fetching blog data:', error);
        }
    };
    fetchData();
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

const handleShow = (blogId) => {
    setEntryToDelete(blogId);
    setShowModal(true);
};

const handleClose = () => {
    setShowModal(false);
    setEntryToDelete(null);
};

// Handle deleting a career
const handleDelete = async (blogId) => { 
    try {
        await axios.put(`/blog/${blogId}`); // Adjust the API endpoint
        setBlogs(blogs.filter(blog => blog._id !== blogId));
        toast.success('Blog deleted Successfully');
        handleClose();
    } catch (error) {
        console.error('Error deleting blog:', error);
        toast.error('Error deleting blog, refresh and try again.');
        handleClose();
    }
};

return (
    <section style={{ padding: "4rem", paddingTop: "1rem" }} className="container-login">
        <div className="heading-text heading-gradient"><h2 style={{ fontSize: "2.5rem" }}><span>Blog entries</span></h2></div>
        <Link to={"/blogportal/addblog"}> 
            <button className="btn btn-outline btn-sm btn-roundeded btn-reveal">
                <span>Add new blog entry</span>
                <i className="icon-chevron-right"></i></button>
        </Link>
        {blogs.length ? (
            <table className="m-t-40 table table-hover">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Blog Title</th> 
                        <th scope="col">Date Posted</th> 
                        <th scope="col">Edit blog</th>
                        <th scope="col">Delete blog</th>
                    </tr>
                </thead>
                <tbody>
                    {blogs.map((blog, index) => ( 
                        <tr key={blog._id}> 
                            <th scope="row">{index + 1}</th>
                            <td className="job-title"> {blog.title}</td> 
                            <td className="date-posted"> {new Date(blog.datePosted).toLocaleDateString()}</td> 
                            <td>
                                <button id={blog._id} onClick={(e) => {
                                    e.preventDefault();
                                    const blogSlug = e.target.id;
                                    navigate(`/blogportal/${blogSlug}`, // Update the navigation path
                                        { state: { blog } }
                                    )}
                                }
                                    className="btn btn-light">
                                    Edit
                                </button>
                            </td>
                            <td>
                                <button className="btn btn-danger" onClick={() => handleShow(blog._id)}>
                                    <span>Delete</span>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <Modal centered show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this news/blog entry?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDelete(entryToDelete)}>Delete</Button>
                </Modal.Footer>
            </Modal>
            </table>
        ) : (
            <p style={{ paddingTop: "5rem" }}>No Blogs to display</p>
        )}
    </section>
    );
};

export default BlogPortal;