import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth.jsx';
import axios from '../../hooks/api.jsx';
// import Particles from '../../components/Particles/particles.jsx';
import "./login.css"
import toast from 'react-hot-toast';

const LOGIN_URL = '/auth';


const Login = () => {
    const { setAuth, persist, setPersist } = useAuth();

    const navigate = useNavigate();
    // const location = useLocation();
    // const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            setAuth({ user, pwd, roles, accessToken });
            setUser('');
            setPwd('');
            toast.success('Login Successful');
            // Navigate to dashboard here after setting the auth
            navigate('/dashboard', { replace: true });
        } catch (err) {
            if (!err?.response) {
                toast.error('Error: No Server Response');
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
                toast.error('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
                toast.error('Unauthorized');
            } else {
                setErrMsg('Login Failed');
                toast.error('Login failed');
            }
            errRef.current.focus();
        }
    }

    const togglePersist = () => {
        setPersist(prev => !prev);
    }

    useEffect(() => {
        localStorage.setItem("persist", persist);
    }, [persist])

    return (
            <section className="w-100 px-3 px-sm-5 px-xl-7">

                <div className="mb-5">
                    <h6 className="h3 mb-1 title-Width">Admin Sign in</h6>
                    <p className="text-muted mb-0">Login to manage the content of the website.</p>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                </div>  

                    <form onSubmit={handleSubmit} className="form-validate" >
                        <div className="form-group">
                            <label htmlFor="email">Username</label>
                            <div className="input-group">
                            <input
                                className='form-control'
                                type="text"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                            />
                                <span className="input-group-text"><i className="icon-user"></i></span>
                            </div>
                        </div>
                        <div className="form-group ">
                            <label htmlFor="password">Password</label>
                            <div className="input-group show-hide-password">
                                <input
                                    className='form-control'
                                    type="password"
                                    id="password"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                />                                        
                                <span className="input-group-text"><i className="icon-eye-off" aria-hidden="true" style={{ cursor: 'pointer' }}></i></span>
                            </div>
                        </div>
                            <button className="btn btn-primary btn-block btn-primary">Sign in</button>
                            <div className="persistCheck">
                                <input
                                    className='tick-box'
                                    type="checkbox"
                                    id="persist"
                                    onChange={togglePersist}
                                    checked={persist}
                                />
                                <label htmlFor="persist">Trust This Device</label>
                            </div>
                    </form>
                    <div className="mt-4 text-center"><small>Not registered or forgot password?</small> <Link to={"/contact"} className="small fw-bold">Contact an Admin</Link>
                    </div>
            </section>
    )
}

export default Login