import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Modal, Button } from 'react-bootstrap'

import "../login.css";
import GoBack from '../Components/goback';
import SpinnerLoading from '../../../components/spinner';

const EditJobIntro = () => {
    const axiosPrivate = useAxiosPrivate();
    const { jobId } = useParams();
    const { state } = useLocation();
    const [data, setData] = useState(null);

    useEffect(() => {
      if(state){
          setData(state.career);
      } else {
          getCarreer();
      }
  }, []);

  const getCarreer = async () => {
    try {
        const formDataObject = new FormData();
        formDataObject.append('id', jobId);
        const response = await axiosPrivate.get(`/fe/careers/${jobId}`, formDataObject,{
            headers: {
            'Content-Type': 'application/json',
            },
        });
        setData(response.data);
    } catch (error) {
        console.error('Error sending email:', error);
        throw error;
      }
    
}


    const job = {
    name: data?.name || '', // Substitute from data or set empty string
    about: data?.about || '', 
    position: {
        title: data?.position?.title || '', 
        overview: data?.position?.overview || '', 
        description: data?.position?.description || '' 
    }, 
    candidate_profile: {
        description: data?.candidate_profile?.description || '' 
    },
    qualifications_and_experience: {
        description: data?.qualifications_and_experience?.description || '' 
    },
    what_we_offer: {
        description: data?.what_we_offer?.description || ''
    },
    application_instructions: {
        submission_details: data?.application_instructions?.submission_details || '',
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  

  useEffect(() => {
  }, [data]);

  const handleChange = (e, field, nestedField) => {
    setData(prevFormData => {
        // Create a completely new object for immutability 
        const newFormData = { ...prevFormData }; 
        if (nestedField) {
            // Same for nested objects
            newFormData[field] = {
                ...newFormData[field],
                [nestedField]: e.target.value
            };
        } else {
            newFormData[field] = e.target.value;
        }
        return newFormData;
    });
};

const handleSave = async (field, nestedField, tempValue) => {
    setIsLoading(true);
    let updatedJob = { ...job }; // Create a copy of the job object
    if (nestedField === undefined) {
      updatedJob[field] = tempValue;
    } else {
      updatedJob[field][nestedField] = tempValue;
    }
    try {
    const response = await axiosPrivate.put(`/careers/${jobId}`, updatedJob);
    if (response.status === 200) {
        toast.success('Job updated Successfully');
       
    } else {
        toast.error('Failed to update job');
    }
    } catch (err) {
        toast.error('Error updating job');
    } finally {
        setIsLoading(false);
    }
};
  
const EditableField = ({ className, field, nestedField, value, isInput }) => {
  const [showModal, setShowModal] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  
  const handleClose = () => {
    setShowModal(false);
  };
  
  const handleOpen = () => {
    setShowModal(true);
    // Set temporary value to the current value when opening the modal
    setTempValue(value);
  };
  
  const handleSaveInner = async () => {
    handleChange({ target: { value: tempValue } }, field, nestedField);
    await handleSave(field, nestedField, tempValue);
    setShowModal(false);
  };
  
  return (
      <>
        <div className="card-header">
          <h3 className='text-uppercase'>{field} {nestedField}</h3>
          <div style={{ whiteSpace: 'pre-wrap' }} className="editable-text" onClick={handleOpen}>
          {value}
          </div>
        </div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          show={showModal} onHide={handleClose}
          centered
         >
          <Modal.Header closeButton>
            <Modal.Title>Edit Fields 
            {isInput ? (
              <p className='text-muted'></p>
            ):( 
              <p className='text-muted'>Breaks will display</p>

            )}
  
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {isInput ? (
                <input
                type="text"
                style={{ marginBottom: '2rem' }}
                className="form-control editable"
                value={tempValue}
                onChange={(e) => setTempValue(e.target.value)}
                />           
             ) : (
              <textarea 
              style={{ minHeight: '350px', marginBottom:"2rem" }}
              className={"form-control "+className}
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
              />
              )}
            <Modal.Footer>
              <Button variant="primary" onClick={handleSaveInner} disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save'}
              </Button>
              <Button variant="danger" onClick={handleClose}>
              Cancel
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  return (
    <> 
      <div className='container-login edit-blog p-r-20'>
      <div className="card-header">
       <div className="heading-text heading-gradient">
          <h2 style={{fontSize:"2.5rem"}}>
            <span>Job editing page</span>
          </h2>
        </div>
        <p className="text-primary">Edit job details by clicking in the text</p>
      </div>
        {isLoading && <SpinnerLoading/>}
        {!isLoading && (
        <form onSubmit={(e) => e.preventDefault()}>
            {/* Render EditableField for each field in the form */}
            <EditableField className="editable" isInput field="name" value={job.name} />
            <EditableField className="editable" field="about" value={job.about} />
            <EditableField className="editable" isInput field="position" nestedField="title" value={job.position.title} />
            <EditableField className="editable" field="position" nestedField="overview" value={job.position.overview} />
            <EditableField className="editable" field="position" nestedField="description" value={job.position.description} />
            <EditableField className="editable" field="candidate_profile" nestedField="description" value={job.candidate_profile.description} />
            <EditableField className="editable" field="qualifications_and_experience" nestedField="description" value={job.qualifications_and_experience.description} />
            <EditableField className="editable" field="what_we_offer" nestedField="description" value={job.what_we_offer.description} />
            <EditableField className="editable" field="application_instructions" nestedField="submission_details" value={job.application_instructions.submission_details} />
        </form>
        )} 
      </div>
      <div className="m-t-10 form-group center">
                <GoBack />
      </div>
  </>
);
};

export default EditJobIntro;