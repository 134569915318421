import { FaHammer, FaWrench, FaNetworkWired, FaWind, FaScrewdriver, FaPaintBrush, FaBuilding, FaHardHat, FaHospitalSymbol, FaBusinessTime } from "react-icons/fa";

const services = [
    {
    icon: <FaHammer />,
    name: "Property Repairs and Maintenance",
    description: "From leaky faucets to electrical issues, our skilled technicians are at your service. We handle everything from minor repairs to major renovations, ensuring your property remains safe and functional.",
    url: "../../images/services/1.jpg"
    },
    {
    icon: <FaWrench />,
    name: "Plumbing Services",
    description: "Our plumbing experts are here to address any plumbing concerns you may have. Whether it's fixing a simple leak or installing complex systems, we've got you covered.",
    url: "../../images/services/2.jpg"
    },
    {
    icon: <FaNetworkWired />,
    name: "Electrical Services",
    description: "Safety and efficiency are paramount when it comes to electrical work. Our certified electricians offer services ranging from installations to troubleshooting and maintenance.",
    url: "../../images/services/3.jpg"
    },
    {
    icon: <FaWind />,
    name: "Heating, Ventilation, and Air Conditioning (HVAC)",
    description: "Stay comfortable all year round with our HVAC services. We provide installation, repair, and maintenance of heating and cooling systems to ensure a comfortable environment.",
    url: "../../images/services/4.jpg"
    },
    {
    icon: <FaScrewdriver />,
    name: "Carpentry and Joinery",
    description: "For all your woodworking needs, our skilled carpenters and joiners can create custom pieces or make repairs to enhance the functionality and aesthetics of your space.",
    url: "../../images/services/5.jpg"
    },
    {
    icon: <FaPaintBrush />,
    name: "Painting and Decorating",
    description: "Transform your property with our painting and decorating services. We use high-quality materials and skilled craftsmen to bring your vision to life.",
    url: "../../images/services/6.jpg"
    },
    {
    icon: <FaBuilding />,
    name: "Building Maintenance",
    description: "Ensure the longevity of your building with our proactive maintenance services. We conduct routine inspections and preventive measures to minimise future issues.",
    url: "../../images/services/7.jpg"
    },
    {
    icon: <FaHardHat />,
    name: "Grounds Maintenance",
    description: "Curb appeal matters, and our grounds maintenance team can keep your property's exterior looking pristine year-round.",
    url: "../../images/services/8.jpg"
    },
    {
    icon: <FaHospitalSymbol />,
    name: "Emergency Services",
    description: "When unexpected issues arise, you need a reliable partner. Our 24/7 emergency services ensure you're never left in the lurch.",
    url: "../../images/services/9.jpg"
    },
    {
    icon: <FaBusinessTime />,
    name: "Commercial Maintenance",
    description: "Business owners can rely on us for a variety of commercial maintenance needs, from office spaces to retail establishments.",
    url: "../../images/services/10.jpg"
    }
];

export default services;