import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from "react-router-dom"
import { FaHome } from "react-icons/fa";
import useAuth from "../../hooks/useAuth";
import useLogout from "../../hooks/useLogout";
import {Toaster} from 'react-hot-toast';

import "./login.css"

const Layout = () => {
    const logout = useLogout();
    const navigate = useNavigate();
    const[role, setRole] = useState("Not Logged in");
    const { auth } = useAuth();
    const roles = auth?.roles;  // Access roles from auth
    const [logState, setLogText] = useState(false);

    const roleCode = auth?.roles;

    const signOut = async () => {
        await logout();
        navigate('/login');
    }

    useEffect(() => {
        if(!roleCode) return;
        if (roleCode[0] === 2000 ) {
            setRole('Admin');
            setLogText(true);
        } else if (roleCode[0] === 1999) {
            setRole('Content Manager');
            setLogText(true);
        } else { 
            setRole('Not Logged in');
            
        }
    }, [roleCode])

    let user = auth?.user;

    return (
        <div className="body-inner">
            <div className="container-fluid">
                <div className="row min-vh-100">
                    <div className="container-login col-md-8 col-lg-7 d-flex align-items-center">
                        <div className="user-info">
                            <p>Welcome {user}</p>
                            <p className='text-primary' style={{ paddingLeft: "2rem" }}>Role: {role}</p>
                            {logState ? (
                                <button onClick={signOut} type="button" className="btn btn-outline btn-sm btn-roundeded btn-reveal">
                                    <span>Log out</span>
                                    <i className="icon-chevron-right"></i>
                                </button>
                            ) : (
                                <button onClick={() => navigate('/login')} type="button" className="btn btn-outline btn-sm btn-roundeded btn-reveal">
                                    <span>Log in</span>
                                    <i className="icon-chevron-right"></i>
                                </button>
                            )}
                        </div>
                        <Outlet />

                        <div className="dashboard">
                            <div className='admin-dash-btn text-center'>
                                <Link className='dashboard-btn' to="/dashboard"> <h4>Admin Dashboard</h4></Link>
                            </div>
                            <div className="header-navbar-btn-box" >
                            </div>
                            <div className="mainMenu">
                                <div className='container'>
                                    <nav>
                                        <ul className='navbar-bottom nav-admin'>
                                            {/* <li className='link-list'><Link to="/login">Login</Link></li> */}

                                            {roles?.includes(2000) ? ( 
                                                <li className='link-list'><Link className='portal-links' to="/users">Manage Users</Link></li>
                                            ) : (
                                                <li className='link-list disabled-link'>Manage Users</li>
                                            )}

                                            
                                            {roles?.includes(2000) ? ( 
                                                <li className='link-list'><Link className='portal-links' to="/portal/quotes">Quotes</Link></li>
                                            ) : (
                                                <li className='link-list disabled-link'>Quotes</li>
                                            )}

                                            {roles?.includes(2000) ? ( 
                                                <li className='link-list'><Link className='portal-links' to="/portal/complaints">Complaints</Link></li>
                                            ) : (
                                                <li className='link-list disabled-link'>Quotes</li>
                                            )}
                                            <li className='link-list'><Link className='portal-links' to="/careersportal">Careers</Link></li>
                                            <li className='link-list'><Link className='portal-links' to="/blogportal">News Editor</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                                </div>
                        </div>
                        
                </div>
                <div className="photo-login col-md-4 col-lg-5 d-none d-md-block bg-cover">
                    <img src="/images/gallery/3.jpg" alt="login" className="img-login h-100 position-absolute" />
                    <Link to="/"><div 
                    className="home-icon-container btn btn-white btn-rounded-only btn-rounded"
                    ><FaHome className="home-icon"/></div></Link>
                </div>
                {/* <Particles color={"#000000"} opacity={.5}  /> */}
            </div>
        </div>
        <Toaster   
        toastOptions={{
            duration: 4000,
            position: 'bottom-right',
            style: {
                border: '1px solid #f9cf09',
                // color: '#713200',
            },
        }}
        />
        </div>
    );
};

export default Layout;