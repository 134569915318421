import React from 'react';
import reviews from './reviews.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactStars from "react-rating-stars-component";
import { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './testimonials.css';

const Testimonials = () => {
  return (
            <div className="container-fluid">
                <Swiper
                    slidesPerView={1}
                    centeredSlides={false}
                    slidesPerGroupSkip={1}
                    grabCursor={true}
                    keyboard={{
                    enabled: true,
                    }}
                    breakpoints={{
                    769: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                    },
                    }}
                    scrollbar={true}
                    navigation={true}
                    pagination={{
                    clickable: true,
                    }}
                    modules={[Keyboard, Scrollbar, Navigation, Pagination]}
                    className="mySwiper"
                >
                {reviews.map((review, index) => (
                    <SwiperSlide key={index}>
                    <div className="equalize testimonial testimonial-box">
                    <div className="testimonial-item">
                        <img src="/images/icons/person.png" alt={`from reviewer`}/>
                        <p>{review.testimonial}</p>
                        <span>{review.name}</span>
                        <span>{review.type} - {review.location}</span>
                        <div className='rating-stars'>
                        <ReactStars
                            count={5}
                            value={review.rating}
                            size={30}
                            edit={false}
                            activeColor="#ffd700"
                            color="rgb(241, 183, 50)"
                        />  
                        </div>    
                    </div>               
                    </div>
                    </SwiperSlide>
                ))}
                </Swiper>
        </div>
  );
};

// <div className="line"></div>
// <h4>Read only</h4>
// <div className="row">
//     <div className="col-md-6">
//         <h5>Present value</h5>
//         <div className="rateit" data-rateit-mode="font" data-rateit-value="2.5" data-rateit-ispreset="true" data-rateit-readonly="true"></div>
//     </div>
//     <div className="col-md-6">
//         <h5>Present value</h5>
//         <div className="rateit" data-rateit-mode="font" data-rateit-value="4.6" data-rateit-ispreset="true" data-rateit-readonly="true"></div>
//     </div>
// </div>

export default Testimonials;


