import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { NavLink } from "../../components/NavLink/navLink";
import {sendEmail, fileUpload, NewsLetterSub} from "../../services/sendMail";
import Selector from "../../components/Selector/selector";
// import data from "../../components/data.json";
import DatePicker from "react-datepicker";
import axios from '../../hooks/api';
import "react-datepicker/dist/react-datepicker.css";
import { validFileSize } from "../../components/validFileSize";
import SEO from '../../components/SEO';
import { useLocation } from 'react-router-dom';

import "./quotes.css";

const Quotes = () => {
  let { preselecType, preselectService } = useParams();

  let [name, setName] = useState("");
  let [company, setCompany] = useState("");
  let [email, setEmail] = useState("");
  let [phone, setPhone] = useState("");
  let [address, setAddress] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [color, setColor] = useState('text-primary');

  let [reference, setReference] = useState("");
  let [referenceOther, setReferenceOther] = useState("");
  let [message, setMessage] = useState("");
  let [service, setService] = useState("");
  let [serviceOther, setServiceOther] = useState("");
  let [property, setProperty] = useState("");
  let [propertyOther, setPropertyOther] = useState("");
  let [location, setLocation] = useState("");
  let [locationOther, setLocationOther] = useState("");
  let [subscription, setSubscription] = useState(false);
  let [selectedDate, setSelectedDate] = useState(null);
  let [budget, setBudget] = useState("");
  let [termsAndConditions, setTermsAndConditions] = useState(false);
  let [isValid, setIsValid] = useState(false);
  let [file, setFile] = useState(null);
  let [fileError, setFileError] = useState(false);

  const navigate = useNavigate()

  const allowedFileTypes = 'image/*,.pdf,.doc,.docx';

  useEffect(() => {
    validateForm();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    email,
    phone,
    address,
    reference,
    message,
    service,
    property,
    location,
    selectedDate,
    termsAndConditions,
    subscription,
    referenceOther,
    serviceOther,
    propertyOther,
    locationOther,
    fileError
    ]);

    useEffect(() => {
      getPresets();
    });

    const getPresets = () => {
      console.log();
      if (preselecType && preselectService && service === '') {
        let matchedService = servicesTypes.find(service => service.value === preselecType);

        if(matchedService) {
          setService(preselecType)
        } else {
          setService('other')
          setServiceOther(preselecType)
        }

        let presetMessage = `Hello, I'm interested in the service of ${preselecType} and I would like a quote for ${preselectService}`
        
        setMessage(presetMessage);
      }
    };

    const fileManager = (incomingFile) => {
      if(validFileSize(incomingFile, 10)){
        setFileError(false)
        setFile(incomingFile)
    } else {
        setFileError(true)
    }
    }

    const referenceTypes = [
      { label: "Internet Search", value: "internet" },
      { label: "Social Media", value: "social Media" },
      { label: "Referral", value: "referral" },
      { label: "Advertisement", value: "advertisement" },
      { label: "Other", value: "other" },
    ];

    let servicesTypes = [
      { label: "Home Repairs", value: "Home Repairs" },
      {
        label: "Commercial Property Maintenance",
        value: "Commercial Property Maintenance",
      },
      {
        label: "Public Infrastructure Services",
        value: "Public Infrastructure Services",
      },
      {
        label: "Environmental Conservation Projects",
        value: "Environmental Conservation Projects",
      },
      { label: "Other", value: "other" },
    ];

    const propertyTypes = [
      { label: "Residential", value: "residential" },
      { label: "Commercial", value: "commercial" },
      { label: "Industrial", value: "industrial" },
      { label: "Public Infrastructure", value: "infrastructure" },
      { label: "Other", value: "other" },
    ];

    const locationTypes = [
      { label: "London", value: "london" },
      { label: "South East", value: "south-east" },
      { label: "South West", value: "south-west" },
      { label: "East of England", value: "east-of-england" },
      { label: "West Midlands", value: "west-midlands" },
      { label: "East Midlands", value: "east-midlands" },
      { label: "North East", value: "north-east" },
      { label: "North West", value: "north-west" },
      { label: "Yorkshire and the Humber", value: "yorkshire-and-the-humber" },
      { label: "Scotland", value: "scotland" },
      { label: "Wales", value: "wales" },
      { label: "Northern Ireland", value: "northern-ireland" },
      { label: "Other", value: "other" },
    ];

    const validateForm = () => {

      const formData = {
        name,
        email,
        phone,
        address,
        reference: reference === 'other' ? referenceOther : reference,
        message,
        service: service === 'other' ? serviceOther : service,
        property: property === 'other' ? propertyOther : property,
        location: location === 'other' ? locationOther : location,
        selectedDate,
        fileError: !fileError,
        termsAndConditions
      };

      const validation = Object.values(formData).every(value => value !== false)
      const otherValidation = ((reference === 'other' && referenceOther === '') || (service === 'other' && serviceOther === '') || (property === 'other' && propertyOther === '') || (location === 'other' && locationOther === ''))
      if(validation) {
        setIsValid(true)
        if(otherValidation) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }
      }else {
          setIsValid(false)
      }

    };

    const submitForm = async (e) => {
      e.preventDefault();
      const formData = {
        name,
        email,
        phone,
        company: company ? company : 'None',
        address,
        service: service === 'other' ? serviceOther : service,
        reference: reference === 'other' ? referenceOther : reference,
        message,
        property: property === 'other' ? propertyOther : property,
        propertyOther,
        location: location === 'other' ? locationOther : location,
        subscription,
        date: `${selectedDate.getDate()}/${selectedDate.getMonth()}/${selectedDate.getFullYear()}`,
        budget: budget ? budget : 'Unknown'
      };

      setIsSubmitting(true);
      
      const uploadData = new FormData(); // Create FormData for image upload
      uploadData.append('name', formData.name);
      uploadData.append('email', formData.email);
      uploadData.append('phone', formData.phone);
      uploadData.append('company', formData.company);
      uploadData.append('address', formData.address);
      uploadData.append('service', formData.service);
      uploadData.append('reference', formData.reference);
      uploadData.append('message', formData.message);
      uploadData.append('property', formData.property);
      uploadData.append('location', formData.location);
      uploadData.append('subscription', formData.subscription);
      uploadData.append('termsAndConditions', termsAndConditions);
      uploadData.append('date', formData.date);
      uploadData.append('budget', formData.budget);
      uploadData.append('file', file);
      try {
          const response = await axios.post('/fe/quotes', uploadData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
          });
          if (response.status === 201) {
              setStatusMessage('Quote submitted sucessfully!');
              setColor('text-success');
          } else {
              setStatusMessage('Quote submission failed');
              setColor('text-danger');
          }
      } catch (err) {
          console.error(err);
      } finally {
          setIsSubmitting(false);
          console.log(isSubmitting);
      }

      try {
        if(file) {
          await fileUpload("quotes", formData, file).then(()=>{
            setIsValid(false)
            if(subscription) NewsLetterSub({email})
            navigate('/confirmation')
            window.scrollTo(0, 0);
        }) 
        } 
        else {
          await sendEmail("quotes", formData).then(()=>{
            setIsValid(false)
            if(subscription) NewsLetterSub({email})
            navigate('/confirmation')
            window.scrollTo(0, 0);
        }) 
        } 
      } catch (error) {
        setIsValid(false)
        navigate('/request-error')
        window.scrollTo(0, 0);
        console.error(error);
      }
    };

      const urlLocation = useLocation();
      const currentUrl = urlLocation.pathname;

      return (
      <>
      <SEO 
      title={"Quotes || S3 Maintenance Services"} 
      description={"Get your Quote today from S3 Maintenance Services."} 
      url={currentUrl}/>
        <div>
          <section id="page-title" className="background-grey-dark text-light">
            <div className="">
              <div className="page-title">
                <h1>Get Your Quote Today!</h1>
              </div>
              <div className="breadcrumb">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li className="active text-light">
                    Get Your Quote
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="text-uppercase">Get Your Quote</h2>
                  <p>
                    Fast, simple and with no compromise. Get Your Personaliced quote
                    today!
                  </p>
                  <div className="m-t-30">
                    <form
                      className="widget-contact-form"
                      data-success-message-delay="40000"
                      noValidate
                      action="include/contact-form.php"
                      method="post"
                      onSubmit={submitForm}
                    >
                      <div className="row">
                        <h3 className="text-uppercase quotes-page-section-header">
                          Quotes Information
                        </h3>
                        <div className="form-group col-md-6">
                          <label htmlFor="name">Name*</label>
                          <input
                            type="text"
                            aria-required="true"
                            name="widget-contact-form-name"
                            required
                            className="form-control required name"
                            placeholder="Enter your Name"
                            onChange={(e) => setName(e.currentTarget.value)}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="email">Email*</label>
                          <input
                            type="email"
                            aria-required="true"
                            name="widget-contact-form-email"
                            required
                            className="form-control required email"
                            placeholder="Enter your Email"
                            onChange={(e) => setEmail(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="subject">Company name</label>
                          <input
                            type="text"
                            name="widget-contact-form-subject"
                            required
                            className="form-control required"
                            placeholder="Enter your Company name"
                            onChange={(e) => setCompany(e.currentTarget.value)}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="number">Phone Number*</label>
                          <input
                            type="tel"
                            name="widget-contact-form-subject"
                            required
                            className="form-control required"
                            placeholder="Enter your Phone Number"
                            onChange={e => setPhone(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="name">Address*</label>
                          <input
                            type="text"
                            aria-required="true"
                            name="widget-contact-form-name"
                            required
                            className="form-control required name"
                            placeholder="Enter your Address"
                            onChange={(e) => setAddress(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <h4 className="text-uppercase quotes-page-section-header">
                          Service Detail
                        </h4>
                        <div className="form-group col-md-6">
                          <label htmlFor="subject">Service Type*</label>
                          <Selector options={servicesTypes} onChange={setService} required={true} value={ service }/>
                        </div>
                      {service === "other" && (
                        <div className="form-group col-md-6">
                          <label htmlFor="number">Please specify your service*</label>
                          <input
                            type="text"
                            name="widget-contact-form-subject"
                            required
                            className="form-control required"
                            value={serviceOther}
                            onChange={(e) => setServiceOther(e.currentTarget.value)}
                          />
                        </div>
                      )}
                      <div className="form-group">
                        <label htmlFor="message">Message*</label>
                        <textarea
                          type="text"
                          name="widget-contact-form-message"
                          required
                          rows="5"
                          value={message}
                          className="form-control required"
                          placeholder="Please describe in detail the service you require"
                          onChange={(e) => setMessage(e.currentTarget.value)}
                        ></textarea>
                      </div>
                    </div>
                      <div className="row">
                        <h4 className="text-uppercase quotes-page-section-header">
                          Location
                        </h4>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="subject">Property Type*</label>
                            <Selector
                              options={propertyTypes}
                              onChange={setProperty}
                              required={true}
                            />
                          </div>
                          {property === "other" && (
                            <div className="form-group col-md-6">
                              <label htmlFor="number">
                                Please specify your type of propety*
                              </label>
                              <input
                                type="text"
                                name="widget-contact-form-subject"
                                required
                                className="form-control required"
                                onChange={(e) => setPropertyOther(e.currentTarget.value)}
                              />
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="subject">Location of Service*</label>
                            <Selector
                              options={locationTypes}
                              onChange={setLocation}
                              required={true}
                            />
                          </div>
                          {location === "other" && (
                            <div className="form-group col-md-6">
                              <label htmlFor="number">
                                Please specify the location*
                              </label>
                              <input
                                type="text"
                                name="widget-contact-form-subject"
                                required
                                className="form-control required"
                                onChange={(e) => setLocationOther(e.currentTarget.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    <div className="row">
                      <h4 className="text-uppercase quotes-page-section-header">
                        Additional Information
                      </h4>
                      <div className="form-group col-md-6">
                        <label>Preferred Service Date*</label>
                        <DatePicker
                          className="quotes-date-picker"
                          selected={selectedDate}
                          onChange={(value) => {setSelectedDate(value)}}
                          dateFormat="dd/MM/yyyy" // You can customize the date format
                          placeholderText="Select a date"
                          required
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="subject">Estimated Budget</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                          <span className="input-group-text">£</span>
                          </div>
                          <input
                            type=""
                            name="widget-budget-form-subject"
                            className="form-control required"
                            placeholder="Enter your Estimated Budget"
                            value={budget}
                            onChange={(e) => setBudget(e.currentTarget.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="subject">Reference Type*</label>
                        <Selector
                          options={referenceTypes}
                          onChange={setReference}
                          label="Reference Type"
                        />
                      </div>
                      {reference === "other" && (
                        <div className="form-group col-md-6">
                          <label htmlFor="number">
                            Please specify your reference*
                          </label>
                          <input
                            type="text"
                            name="widget-contact-form-subject"
                            required
                            className="form-control required"
                            onChange={(e) => setReferenceOther(e.currentTarget.value)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label>Attachments (Optional)</label>
                        <input
                          className="form-control"
                          type="file"
                          id="formFile"
                          aria-label="add file if necesary"
                          accept={allowedFileTypes}
                          onChange={(e) => fileManager(e.target.files[0])}
                        ></input>
                        {
                            fileError ? 
                            <label style={{ fontSize: 'xx-small', color: 'red'}}>The file exceeds the maximum size of 10Mb. Please try again</label> :''
                        }
                      </div>
                    </div>

                    <div className="row" style={{ paddingLeft: "15px" }}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="quotes-promotions"
                          aria-label='recive promotions checkbox'
                          onChange={() => setSubscription(!subscription)}
                          checked={subscription}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          I would like to receive updates and promotions from S3
                          maintenance ltd
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="quotes-terms&conditions"
                          aria-label='accept terms & conditions checkbox'
                          onChange={() =>
                            setTermsAndConditions(!termsAndConditions)
                          }
                          checked={termsAndConditions}
                        />
                        <label className="form-check-label">
                        By submitting this form, you agree to our [ 
                        <NavLink to={"/privacy-policy"}>
                          Privacy Policy
                        </NavLink>
                        ] and [ 
                        <NavLink to={"/terms"}>Terms and Conditions</NavLink>
                        ].
                      </label>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary"
                      type="submit"
                      id="form-submit"
                      disabled={!isValid}
                      onSubmit={submitForm}
                    >
                      <i className="fa fa-paper-plane"></i>
                      &nbsp;Send message
                    </button>
                  </form>
                  <div className={color+" form-group col-md-12"}>
                          {statusMessage}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
      </>
  );
};

export default Quotes;
