import React, {useState} from "react";
// import { useLocation } from "react-router-dom";
import { NavLink } from "../NavLink/navLink";
// import logo from "../../images/logos/logo.png";
import SocialMedia from "../socialmedia";
import { sendEmail } from "../../services/sendMail";

import "./footer.css";

const d = new Date();
let year = d.getFullYear()

const Footer = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [validForm, setValidForm] = useState(false);
    const [requestSuccess, setRequestSuccess] = useState(false);

    // const handleClick = (event) => {
    //   event.preventDefault();
    //   window.scrollTo(0, 0);
    // };
  
//   const location = useLocation().pathname;
    const handleContactForm = async (e) => {
        e.preventDefault()
        const formData = {
            name,
            email,
            message
        }
        const validation = Object.values(formData).every(value => value != false);

        if(validation) {
            setValidForm(true)
            await sendEmail('contact', formData)
            .then(()=> {
                setRequestSuccess(true)
            })
            .catch((err) => {
                console.log('Error sending email: ' + err);
                setRequestSuccess(false)
            })
        } else setValidForm(false)

        setShowMessage(true);
        
    }

    const displayMessage = () => {
        if(showMessage) {
            let messageContent;
            let error = true;

            if(requestSuccess){
                messageContent = 'Thanks for your interest, we will contact you soon';
                error = false;
            } else messageContent = 'An error has ocurred, please try again later';
            if(!validForm) messageContent = 'Please fill all the fields correctly';

            return (
                <p className={error ? 'footer-form-error': 'footer-form-success'}>{messageContent}</p>
            )
        }

    }

    return (
			<footer id="footer" className="background-grey-light">
				<div className="footer-content">
					<div className="container">
						<div className="row">
							<div className="col-lg-4  col-lg-2 col-md-4">
								<div className="widget  widget-contact-us">
									<h4>S3 Maintenance Services</h4>
									<ul className="list-icon">
										<li>
											<i className="fa fa-map-marker-alt"></i>
											<span>
												400a Finchley Road <br />
												<i className="fa-map-marker-alt"></i>
												NW2, 2HR, London
											</span>
										</li>
										{/* <li>
                                            <i className="fa fa-phone"></i>
                                            (123) 456-7890 
                                        </li>
                                        <li>
                                            <i className="far fa-envelope"></i>
                                            <a href="mailto:info@s3maintenance.co.uk">info@s3maintenance.co.uk</a>
                                        </li> */}
										<li style={{ display: "flex", flexDirection: "row" }}>
											<i
												style={{ marginTop: ".7rem" }}
												className="far fa-clock"
											></i>
											<span>
												<strong>Office</strong>
												<br />
												Monday - Friday: <strong>09:00 - 18:00</strong>
												<br />
												Weekends: <strong>Emergency calls</strong>
											</span>
										</li>
										<li style={{ display: "flex", flexDirection: "row" }}>
											<i
												style={{ marginTop: ".7rem" }}
												className="far fa-clock"
											></i>
											<span>
												<strong>Support</strong>
												<br />
												<strong>24/7 service available</strong>
											</span>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-xl-2 col-lg-2 col-md-4">
								<div className="widget">
									<h4>LINKS</h4>
									<ul className="list">
										<li>
											<NavLink to="/services/quotes">Request a quote</NavLink>
										</li>
										<li>
											<NavLink to="/blog">News</NavLink>
										</li>
										<li>
											<NavLink to="/pricing">Pricing</NavLink>
										</li>
										<li>
											<NavLink to="/careers">Careers</NavLink>
										</li>
										<li>
											<NavLink to="/sitemap">Site Map</NavLink>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-xl-2 col-lg-2 col-md-4">
								<div className="widget">
									<h4>SUPPORT</h4>
									<ul className="list">
										<li>
											<NavLink to={"/complaints"}>Complaints</NavLink>
										</li>
										<li>
											<NavLink to={"/terms"}>Terms of Service</NavLink>
										</li>

										<li>
											<NavLink to={"/privacy-policy"}>
												Privacy and Cookies
											</NavLink>
										</li>
										<li>
											<NavLink to={"/faqs"}>FAQS</NavLink>
										</li>
										<li>
											<NavLink to={"/contact"}>Contact us</NavLink>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-4">
								{/* { location !== '/contact' && */}
								<form className="" noValidate>
									<div className="input-group mb-2">
										<div className="input-group-prepend">
											<span className="input-group-text" id="footer-form-name-icon">
												<i className="fa fa-user"></i>
											</span>
										</div>
										<input
											type="text"
											aria-required="true"
											className="form-control required name"
											placeholder="Enter your Name"
                                            onChange={(e) => setName(e.currentTarget.value)}
										/>
									</div>
									<div className="input-group mb-2">
										<div className="input-group-prepend">
											<span className="input-group-text" id="footer-form-email-icon">
												<i className="fa fa-envelope"></i>
											</span>
										</div>
										<input
											type="email"
											aria-required="true"
											required
											className="form-control required email"
											placeholder="Enter your Email"
                                            onChange={(e) => setEmail(e.currentTarget.value)}
										/>
									</div>
									<div className="form-group mb-2">
										<textarea
											type="text"
											rows="5"
											className="form-control required"
											placeholder="Enter your Message"
                                            onChange={(e) => setMessage(e.currentTarget.value)}
										></textarea>
									</div>
									<div className="form-group">
										<button
											className="btn btn-primary"
											type="button"
											onClick={handleContactForm}
										>
											<i className="fa fa-paper-plane"></i>
											&nbsp;Contact Us
										</button>
                                        {
                                            displayMessage()
                                        }
									</div>
								</form>
								{/* } */}
							</div>
						</div>
					</div>
				</div>
				<div className=" background-grey-dark copyright-content">
					<div className="container">
						<div className="row">
							<div className="col-lg-6">
								<SocialMedia class={"social-icon"} type={true} />
							</div>
							<div className="text-grey-light col-lg-6">
								<div className="text-grey-light copyright-text text-end">
									&copy;{year} S3 Maintenance. All Rights Reserved.
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
    };

    export default Footer;
