import React, { Suspense, lazy } from 'react';
import Particle from '../../components/Particles/particles';
import Map from '../../components/Map/map';
import data from '../../components/data.json'; 
import { simulatedCall } from '../../components/simulatedCall';
import Spinner from '../../components/Spinner/spinner';
import SEO from '../../components/SEO.jsx';

import { useLocation } from "react-router-dom";

import "./projects.css";
import Photos from '../../components/PhotoAlbum/photoalbum';

const Projects = () => {
  const LazyCounter = lazy(() => simulatedCall(import('../../components/Counter/counters')));

  const location = useLocation();

  return (
		<>
			<SEO
				title={"Projects"}
				description={
					"Experienced maintenance specialists managing large-scale property portfolios throughout the UK and Ireland.  We optimize operations and streamline maintenance for landlords nationwide."
				}
				url={location.pathname}
			/>
			<div className="container-projects">
				<Suspense fallback={<Spinner />}>
					<div className="container-particles">
						<section
							id="page-title"
							className="background-none text-light"
							style={{ position: "relative" }}
						>
							<div className="container">
								<div className="row">
									<div style={{ marginBottom: "2rem" }} className="page-title">
										<h1>Projects</h1>
										<span>
											We are your dedicated maintenance experts, proud to serve
											the entire UK national market. With site all over the UK and Ireland.
										</span>
									</div>
									<LazyCounter div={3} blackWhite={"w"} />
								</div>
							</div>
						</section>
						<Particle color={"#ffffff"} opacity={0.05} />
					</div>
				</Suspense>
				<Map />

				<section id="section2" className="m-t-10 no-padding background-white">
					<div className="m-t-30 m-b-40 heading-text text-center heading-section">
						<h2>Our sites</h2>
					</div>
					<Photos data={data.projects} />
				</section>
			</div>
		</>
	);
}

export default Projects