import React from 'react';

import './values.css';

const Values = (data) => {
  return (
    <div className={data.container+ " values-container text-center"}>
        <div className="row">
          {data.data.map((values, index) => (
            <div key={index} className="values-box col-lg-4">
                {/* <img src="../../homepages/construction/images/icons/1.png" alt=""> */}
                <p className='text-lg text-uppercase values-index'>{index+1}.</p>
                <h2>{values.title}</h2>
                <p className='values-text'>{values.text}</p>
            </div>
            ))}
          {/* <Particles color={"#f95909"} opacity={.25} /> */}
        </div>
    </div>
  );
};

export default Values;
