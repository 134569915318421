import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Modal, Button} from 'react-bootstrap'
import { validFileSize } from '../../../components/validFileSize';

import "../login.css"; 
import GoBack from '../Components/goback';
import SpinnerLoading from '../../../components/spinner';

const EditBlogEntry = () => {
    const axiosPrivate = useAxiosPrivate();
    const { slug } = useParams();
    const { state } = useLocation();
    const blogEntry = state.blog; // Ensure that blog entry is provided via state
    
    const [blog, setBlog] = useState({
        datePosted: blogEntry?.datePosted || new Date(),
        title: blogEntry?.title || '',
        description: blogEntry?.description || '',
        images: blogEntry?.images || '',
        type: blogEntry?.type || 'News',
        body: blogEntry?.body || '',
        links: blogEntry?.links || [],
    });
    
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [saveError, setSaveError] = useState(null);

    const [fileError, setFileError] = useState(false);



    const handleChange = (e, field) => {
        setBlog(prevFormData => ({
        ...prevFormData,
        [field]: e.target.value
        }));
    };
    const handleImageChange = (event) => {
        const file =  event.target.files[0];
        if(validFileSize(file, 5)){
            setFileError(false)
            setBlog({
                ...blog,
                image: event.target.files[0] // Store the selected image file
            });
        } else {
            setFileError(true)
        }
    };

    const handleSave = async (field,tempValue) => {
        setSaveError(null);
        setIsLoading(true);


        try {
          // Prepare data for the update
            let updateData = { ...blog };
            if (field !== undefined) {
                updateData[field] = tempValue;
            }
                console.log(updateData)
                //Axios if and else - image or text
                if (updateData.image) {
                    const formData = new FormData();
                    formData.append('image', updateData.image);
                    formData.append('title', updateData.title);
                    formData.append('description', updateData.description);
                    formData.append('type', updateData.type);
                    formData.append('body', updateData.body);
                    formData.append('links', updateData.links);

                    const response = await axiosPrivate.put(`/blog/image/${slug}`, formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data',
                        // "is-image": true
                        }
                        });
                    
                    setBlog({
                        ...blog,
                        images: response.data.images, // Store the selected image file
                        image: null
                    });
                    console.log(response.data.images);
                } else {
                    const response = await axiosPrivate.put(`/blog/edit/${slug}`, updateData);
                        if (response.status === 200) {
                            setIsSaved(true);
                            toast.success('Blog entry updated Successfully');
                            console.log(response)
                        } else {
                            toast.error('Failed to update blog entry');
                            setSaveError('Failed to update blog entry');
                        }
                    } 
            } catch (err) {
                toast.error('Error updating blog entry');
                setSaveError('Error updating blog entry');
                } finally {
                setIsLoading(false);
                }
      };
   
    const EditableField = ({ field, value, isTextArea, isLinks }) => {
        const [showModal, setShowModal] = useState(false);
        const [tempValue, setTempValue] = useState(value);
        const [linkIndex , setLinkIndex] = useState(null); // Track the selected link index

        const handleClose = () => setShowModal(false);

        const handleSaveInner = async () => {
            if (field === 'links') {
                let links = blog.links;
                links[linkIndex] = tempValue;
                handleChange({ target: { value: links } }, field);
                await handleSave(field, links);
            }else{
                handleChange({ target: { value: tempValue } }, field);
                await handleSave(field, tempValue);
            }
            setShowModal(false);
        };

        const handleOpen = (index) => {
            setShowModal(true);
            setTempValue(value);
            setLinkIndex(index);
            if (field === 'links') {
                setTempValue(value[index]); // Set tempValue to the specific link
                // setSelectedLinkIndex(index);
            }
        };

        const [newLink, setNewLink] = useState('');
        const handleAddLink = async () => {
            if (newLink) {
            setBlog(prevData => {
            const updatedLinks = [...prevData.links, newLink];
            // Save the backend right after updating the state
            handleSave('links', updatedLinks);
            return { ...prevData, links: updatedLinks };
            });
            setNewLink(''); // Clear the input field
            }
        };
        const handleDeleteLink = async (index) => {
            setBlog(prevData => {
            const updatedLinks = prevData.links.filter((_, i) => i !== index);
            // Save the backend right after updating the state
            console.log(updatedLinks)
            handleSave('links', updatedLinks);
            return { ...prevData, links: updatedLinks };
         });
        };
        const renderArrayItems = (arr) => {
            console.log(arr)
            return (
                <div className="card-header">
                <h3 className='text-uppercase'>{field}</h3>
                <div> {/* Wrapper for links and adding */}
                  {arr.map((item, index) => (
                    <ul 
                      style={{paddingBottom:"2rem"}} 
                      className="link-list-item"  
                      key={index} 
                    > 
                    <div className="card-header image-form">
                        <li className='editable-text'
                                style={{  
                                whiteSpace: 'pre-wrap', 
                                overflowWrap: 'break-word', 
                                wordWrap: 'break-word',
                                overflow: 'hidden',
                                margin:"auto",
                                inlineSize: "100%"}} 
                                onClick={() => handleOpen(index)} 
                                >
                            {item}
                        </li>
                        <Button className='btn-delete' variant="danger" size="sm" onClick={() => handleDeleteLink(index)}>Delete</Button> 
                    </div>
                    </ul> 
                  ))}
                  {/* Adding New Link */}
                  <div className="card-header">
                    <div className="d-flex align-items-center">
                        <input 
                        type="text" 
                        className="form-control editable mr-2"
                        value={newLink}
                        onChange={(e) => setNewLink(e.target.value)}
                        />
                        <Button className='btn-add' variant="primary" onClick={handleAddLink}>Add Link</Button>
                    </div>
                  </div>
                </div>
              </div>
            )
          };

        return (
            <>{isLinks ? renderArrayItems(value) 
                :
                <div className="card-header">
                    <h3 className='text-uppercase'>{field}</h3>
                    <div style={{ whiteSpace: 'pre-wrap' }} className="editable-text" onClick={handleOpen}>
                          {value}
                    </div>
                </div>
                }
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={showModal} onHide={handleClose}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit {field}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isTextArea ? (
                            <textarea
                                style={{ minHeight: '350px', marginBottom:"2rem" }}
                                className={"form-control editable"}
                                value={tempValue}
                                onChange={(e) => setTempValue(e.target.value)}
                            />
                        ) : field === 'type' ? ( 
                            <div className="form-group">
                              <label htmlFor="type">Subject</label>
                              <select id="type" name="type" className="form-control" value={tempValue} onChange={(e) => setTempValue(e.target.value)} required>
                                <option value="Sustainability">Sustainability</option>
                                <option value="Regulations">Regulations</option>
                                <option value="Health & Safety">Health & Safety</option>
                                <option value="News">News</option>
                                <option value="Removals">Removals</option>
                                <option value="Equipment & Tools">Equipment & Tools</option>
                                <option value="Cost-Saving Strategies">Cost-Saving Strategies</option>
                                <option value="Industry Insights">Industry Insights</option>
                                <option value="Customer Focused">Customer Focused</option>
                              </select> 
                            </div>
                          ) : (
                            <input
                                type="text"
                                className={"form-control editable"}
                                value={tempValue}
                                onChange={(e) => setTempValue(e.target.value)}
                            />
                        )}
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleSaveInner} disabled={isLoading}>
                                {isLoading ? 'Saving...' : 'Save'}
                            </Button>
                            <Button variant="danger" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
            </>
        );
    };

    return (
        <>
            <div className='container-login edit-blog p-r-20'>
                <div className="card-header">
                    <div className="heading-text heading-gradient">
                        <h2 style={{fontSize:"2.5rem"}}>
                            <span>Blog Editing Page</span>
                        </h2>
                    </div>
                    <p className="text-primary">Edit blog entry details by clicking in the text</p>
                </div>
                {isLoading && <SpinnerLoading />}
                {!isLoading && (
                    <form className="edit-form" onSubmit={(e) => e.preventDefault()}>
                        <EditableField field="title" value={blog.title} />
                        <EditableField field="description" value={blog.description} isTextArea />
                        <div style={{borderBottom:"none"}} className="card-header">
                                <h3 className='text-uppercase'>Image</h3>
                                <img src={blog.images} alt={blog.title} />
                            </div>
                            <div className="card-header form-group image-form">
                                <div>
                                    <label htmlFor="image">Select a new image(5Mb max):</label>
                                    <input type="file" id="image" name="image" accept="image/jpeg, image/png, image/gif" onChange={(event) => handleImageChange(event)} style={{minHeight: 'auto'}}/>
                                    {
                                        fileError ? 
                                        <label style={{ fontSize: 'xx-small', color: 'red'}}>The file exceeds the maximum size of 5Mb. Please try again</label> :''
                                    }
                                </div>
                                <Button className='right btn-image' variant="primary" onClick={handleSave} disabled={isLoading || !blog.image}>
                                        {isLoading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        <EditableField field="type" value={blog.type} />
                        <EditableField field="body" value={blog.body} isTextArea />
                        <EditableField field="links" value={blog.links} isLinks={true} />
                        {isSaved && <p>Changes saved successfully!</p>}
                        {saveError && <p className="error-message">{saveError}</p>}
                    </form>
                )}
            </div>
            <div className="m-t-10 form-group right add-job-container">
                <GoBack />
            </div>
        </>
    );
};

export default EditBlogEntry;